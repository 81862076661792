import { useParams } from 'react-router-dom';
import DynamicPagedContent from '../components/DynamicPagedContent';
import SpecialList from '../components/SpecialList';

function PageComponent({ data }) {
  return <SpecialList data={data} type="specials" />;
}

function SpecialsRoute() {
  const {
    langId,
  } = useParams();

  return (
    <DynamicPagedContent
      langId={langId}
      method="GET"
      apiUrl={`api/${langId}/specials`}
      dataField="data"
      PageComponent={PageComponent}
    />
  );
}

export default SpecialsRoute;
