import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';

function ChoosenPlanCard(props) {
  const {
    duration,
    price,
    features,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="choosen-plan">
      <span className="your-choice">
        {t('your_choice')}
      </span>
      <div className="d-flex">
        <span className="material-symbols-rounded">
          check_circle
        </span>

        <div className="choosen-plan-details">
          <div className="duration">
            {t(duration)}
          </div>
          <div className="title-xl">
            {`${price} euro`}
          </div>
        </div>
      </div>
      <div className="choosen-plan-features mx-3">
        {_map(features, (feature) => (
          <div key={feature} className="feature">
            {feature}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChoosenPlanCard;
