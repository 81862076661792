import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import _isFunction from 'lodash/isFunction';
import Configuration from '../../providers/config.Provider';
import Link from '../SmartLink';

function CardBody(props) {
  const {
    title,
    size,
    href,
    categories,
    subscription,
  } = props;

  const { getName } = Configuration().config;

  const sections = _uniqBy(categories, ('area_id'));

  return (
    <div>
      {subscription || categories
        ? (
          <div className="mb-3">
            {subscription
              ? (
                <span className="badge bg-secondary text-sm s2s-badge-lg me-2">
                  S2S+
                </span>
              )
              : ''}
            {sections && (
              _map(sections, ({ area_id: areaId }) => {
                const section = _isFunction(getName) ? getName(areaId) : '-';
                return (
                  <span className={`s2s-category category-${areaId} me-2`} key={`s2s-category-area-${areaId}`}>
                    {section}
                  </span>
                );
              })
            )}
          </div>
        )
        : ''}
      <h3 className={`title-${size} card-title`}>
        <Link
          href={href}
        >
          {title}
        </Link>
      </h3>
    </div>
  );
}

export default CardBody;
