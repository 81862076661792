import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import DynamicContent from '../components/DynamicContent';
import Loader from '../components/Loader';
import Configuration from '../providers/config.Provider';

function Page(props) {
  const {
    layout,
    api,
  } = props;

  let { id } = props;

  const params = useParams();
  const { langId } = params;

  if (id === undefined) {
    id = params.id;
  }

  const {
    updateFromTemplate,
  } = Configuration();

  const { data, loading, error } = useFetch({
    url: `api/${langId}/layout/${layout}`,
    method: 'POST',
    body: {
      inject: id ? `${api}/${id}` : api,
    },
    then: (d) => {
      updateFromTemplate(d);
    },
  });

  return (
    <Loader error={error} loading={loading}>
      <DynamicContent data={data?.layout} />
    </Loader>
  );
}

export default Page;
