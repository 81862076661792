import _map from 'lodash/map';
import _join from 'lodash/join';
import _size from 'lodash/size';
import Card from './Card';

function ArticleList(props) {
  const {
    articles,
    sizes = ['sm', 'sm', 'sm'],
    titleSizes,
    isHorizontal,
  } = props;

  const mod = _size(articles) % 3;
  const filler = (mod === 0)
    ? []
    : new Array(mod).fill({ placeholder: true });

  const list = [
    ...articles,
    ...filler,
  ];

  // if (_size(articles) === 2 && sizes[1] && sizes[1] !== sizes[0]) {
  //   list[1].imgSquare = true;
  // }

  return (
    <div className="d-flex flex-wrap article-list" style={{ justifyContent: 'space-between' }}>
      {_map(
        list,
        (article, index) => {
          if (article.placeholder) {
            return (<div style={{ width: '32%' }} key={`ph-${index}`} />);
          }
          const {
            id,
            tags,
            image,
            title,
            slug,
            summary: content,
            // section,
            created_at: updatedAt,
            published_at: publishedAt,
            // attachment,
            categories,
            highlight,
            advertorial,
            imgSquare,
            // created_at,
            // highligh,
            // video_id,
            // image_id,
            // photogallery_id,
            // podcast_id,
            subscription,
          } = article;

          const titleSize = titleSizes ? titleSizes[index] : sizes[index];
          const contentNoHTML = content ? content.replace(/(<([^>]+)>)/gi, '') : '';

          return (
            <Card
              key={id}
              size={sizes[index]}
              text={contentNoHTML}
              categories={categories}
              subscription={subscription}
              date={publishedAt ?? updatedAt}
              imgSrc={image?.url}
              imgAlt={image?.description}
              imgSquare={imgSquare}
              href={`${slug}`}
              title={title}
              titleSize={titleSize}
              tag={_join(tags, ' ')}
              isHorizontal={!isHorizontal}
              advertorial={advertorial}
              highlight={highlight}
            />
          );
        },
      )}
    </div>
  );
}

export default ArticleList;
