import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import DynamicPagedContent from '../components/DynamicPagedContent';
import YearMonthSelector from '../components/YearMonthSelector';
import MagazineElementList from '../components/MagazineElementList';

function Magazine() {
  const {
    langId,
  } = useParams();
  const [m, setM] = useState('');
  const [y, setY] = useState('');

  const { t } = useTranslation();

  return (
    <>
      <YearMonthSelector
        name={t('magazine')}
        year={y}
        month={m}
        setYear={setY}
        setMonth={setM}
        getYearsUrl={`api/${langId}/magazines/years`}
        getMonthsUrl={(mm) => `api/${langId}/magazines/months/${mm}`}
      />
      <DynamicPagedContent
        langId={langId}
        method="GET"
        apiUrl={`api/${langId}/magazines`}
        apiUrlParams={`year=${y}&month=${m}`}
        PageComponent={MagazineElementList}
        dataField="data"
      />
    </>
  );
}

export default Magazine;
