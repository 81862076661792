import Link from '../SmartLink';
import PrettyDate from '../PrettyDate';
import Image from '../Image';
import createSlug from '../../utils/createSlug';

function GalleryCard({
  title, image, id, date, desc, slug
}) {
  const href = `photogallery/${slug}`;

  return (
    <div className="card card-gallery">
      <article>

        <Link href={href} className="link-img-container">
          <div className="img-container">
            <Image url={image} alt={desc} className="card-img-top" />
          </div>

          <span className="material-icons-outlined">
            photo_camera
          </span>
        </Link>

        <div className="card-body">
          <h4 className="title-sm card-title">
            <Link href={href}>
              {title}
            </Link>
          </h4>

          {date
            ? (
              <div className="text-xs text-uppercase text-primary">
                <PrettyDate date={date} />
              </div>
            )
            : null}
        </div>

      </article>
    </div>

  );
}

export default GalleryCard;
