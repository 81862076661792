import Link from '../SmartLink';
import Image from '../Image';
import PrettyDate from '../PrettyDate';
import createSlug from '../../utils/createSlug';

function PodcastItem({ podcast }) {
  const {
    title,
    id,
    image,
    slug,
    subtitle,
    created_at: createdAt,
    published_at: publishedAt,
  } = podcast;

  const date = publishedAt ?? createdAt;

  return (
    <div className="podcast mb-3 mb-lg-0">
      <Link className="mb-2 d-block" href={`podcast/${slug}`}>
        <div className="img-container mb-3">
          <Image url={image?.url} alt={title} />
        </div>
        <div className="podcast-title title-sm">
          {title}
        </div>
      </Link>
      <p>
        {subtitle}
      </p>
      <div className="text-xs text-uppercase text-primary">
        {date ? <PrettyDate date={date} /> : null}
      </div>
    </div>
  );
}

export default PodcastItem;
