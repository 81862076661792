import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function SearchBar(props) {
  const { isSearchOpen, toggleSearch } = props;
  const { langId } = useParams();
  const [value, setValue] = useState();
  const navigate = useNavigate();

  const toggle = () => {
    toggleSearch();
    setValue('');
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    navigate(`/${langId}/archive?term=${value}`);
    toggle();
  };

  return (
    <section
      id="s2s-searchBar"
      style={{ zIndex: 1000 }}
      className={isSearchOpen ? 'menu-open' : ''}
    >
      <div className="container-xxl">
        <div className="row">
          <div className="col">
            <div id="s2s-searchForm">
              <form onSubmit={onFormSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    id="s2s-searchField"
                    className="form-control"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={t('search.placeholder')}
                  />
                  <button type="submit" style={{ display: 'none' }}>
                    {t('search.text')}
                  </button>
                </div>
              </form>
            </div>
            <div
              id="s2s-closeSearchButton"
              onClick={toggle}
              onKeyDown={toggle}
              tabIndex={0}
              role="button"
            >
              <span className="material-icons-outlined">close</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SearchBar;
