import ReactIframe from 'react-iframe';
import SectionHeader from './SectionHeader';

function Iframe(props) {
  const { url, title, size = {} } = props;
  const {
    height = 361,
    width = 624,
  } = size;

  return (
    <div className="text-center">
      { title ? <SectionHeader title={{ text: title }} /> : ''}
      <ReactIframe
        url={url}
        height={height}
        width={width}
        styles={{ borderRadius: '12px' }}
      />
    </div>
  );
}

export default Iframe;
