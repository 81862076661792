import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import fetchFn from '../utils/fetchFn';
import DynamicContent from './DynamicContent';
import Paginator from './Paginator';

function DynamicPagedContent(props) {
  const { langId } = useParams();
  const {
    method = 'POST',
    apiUrl = `api/${langId}/layout/article_list`,
    apiUrlParams = '',
    injectUrl,
    injectUrlParams = '',
    PageComponent = DynamicContent,
    dataField,
  } = props;

  const [GP, setGP] = useState();

  useEffect(
    () => {
      function getPage(pageId) {
        const url = (injectUrl)
          ? `${apiUrl}?${apiUrlParams}`
          : `${apiUrl}?page=${pageId}&${apiUrlParams}`;
        const req = {
          method,
          url,
        };
        if (method === 'POST' && injectUrl) {
          req.body = {
            inject: `${injectUrl}?page=${pageId}&${injectUrlParams}`,
          };
        }
        return fetchFn(req);
      }
      // in order to store a function into react store
      // it must be wrapped in an anonymous function
      // see https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
      setGP(() => getPage);
    },
    [injectUrl, injectUrlParams, apiUrl, apiUrlParams],
  );

  return (
    <Paginator
      PageComponent={PageComponent}
      getPage={GP}
      dataField={dataField}
    />
  );
}

export default DynamicPagedContent;
