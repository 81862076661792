import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import fetchFn from '../utils/fetchFn';

function UpdatePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();

  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const { langId } = useParams();

  const { t } = useTranslation();

  const submitPassword = () => {
    if (password !== newPassword) {
      setError('passowrd mismatch');
      return;
    }
    fetchFn({
      url: `api/${langId}/auth/update_password`,
      method: 'POST',
      body: { oldPassword, password },
    }).then(() => {
      // todo: aggiungere conferma
    });
  };

  return (
    <div className="row">
      <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
        <h1 className="mb-5">{t('update_password')}</h1>
        <div className="account-panel">
          <Row className="mt-2">
            <Col>
              <Form.Label>{t('old_password')}</Form.Label>
              <Form.Group>
                <div className="text-form">
                  <Form.Control
                    type={showPasswordOld ? 'text' : 'password'}
                    placeholder={`${t('password')}`}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="show-password"
                    onClick={() => setShowPasswordOld(!showPasswordOld)}
                  >
                    <span className="material-icons-outlined">
                      {showPasswordOld ? 'visibility_off' : 'visibility'}
                    </span>
                  </button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="account-panel">
          <Row className="mt-2">
            <Col>
              <Form.Label>{t('password')}</Form.Label>
              <Form.Group>
                <div className="text-form">
                  <Form.Control
                    type={showPassword1 ? 'text' : 'password'}
                    placeholder={`${t('password')}`}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="show-password"
                    onClick={() => setShowPassword1(!showPassword1)}
                  >
                    <span className="material-icons-outlined">
                      {showPassword1 ? 'visibility_off' : 'visibility'}
                    </span>
                  </button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="account-panel">
          <Row className="mt-2">
            <Col>
              <Form.Label>{t('confirm_new_password')}</Form.Label>
              <Form.Group>
                <div className="text-form">
                  <Form.Control
                    type={showPassword2 ? 'text' : 'password'}
                    placeholder={`${t('password')}`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="show-password"
                    onClick={() => setShowPassword2(!showPassword2)}
                  >
                    <span className="material-icons-outlined">
                      {showPassword2 ? 'visibility_off' : 'visibility'}
                    </span>
                  </button>
                </div>
              </Form.Group>
            </Col>
          </Row>
          {error && <div>{error}</div>}
          <Row>
            <Col className="my-3">
              <Button onClick={submitPassword} className="s2s-btn-accent text-uppercase btn-accent">
                {t('account.update')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default UpdatePassword;
