import getImgSrc from '../utils/getImgSrc';

function Image(props) {
  const {
    url, alt, className,
  } = props;

  return (
    <img
      className={className}
      src={getImgSrc(url)}
      alt={alt}
      onError={(e) => { e.target.style.display = 'none'; }}
    />
  );
}

export default Image;
