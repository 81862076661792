import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import Carousel from '../Carousel';
import GalleryCard from './Card';
import Banner from '../Banner/Banner';
import SectionHeader from '../SectionHeader/Header';
import Link from '../SmartLink';
import createSlug from '../../utils/createSlug';
import PrettyDate from '../PrettyDate';

function Gallery({
  id,
  images = [],
  href,
  title,
  slug,
  date,
  cards = [],
  leaderboard = {},
}) {
  const { t } = useTranslation();

  const mainHref = `photogallery/${slug}`;

  return (
    <section className="mb-4">
      <div className="row">
        <div className="col-12">
          <div className="container-xxl bg-primary-dark s2s-section-bg mb-3">

            <SectionHeader
              className="section-title section-title-no-line mb-4 mx-3 mt-3"
              iconHref="photogalleries"
              title={{
                text: t('gallery'),
                href,
              }}
            />

            <div className="row mb-3 mx-1">
              <div className="col-lg-9">
                <Carousel
                  images={images}
                />
                <h3 className="title-xl card-title">
                  <Link href={mainHref}>{title}</Link>
                </h3>
                <div className="text-xs text-uppercase text-primary mb-5">
                  <PrettyDate date={date} />
                </div>
                {!_isEmpty(leaderboard)
                  ? (
                    <Banner
                      className="mb-5"
                      href={leaderboard?.url}
                      alt={leaderboard?.alt}
                      src={leaderboard?.image_desktop}
                    />
                  )
                  : null}
              </div>

              <div className="col-lg-3">
                <div className="row">
                  {_map(cards, ({
                    title: titleI, slug: slugI, image, id: idI, published_at: publishedAt,
                  }) => (
                    <div className="col-md-4 col-lg-12" key={idI}>
                      <GalleryCard
                        id={idI}
                        title={titleI}
                        slug={slugI}
                        image={image?.url}
                        date={publishedAt}
                        desc={image?.description}
                      />
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </section>
  );
}

export default Gallery;
