/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import BannerList from './Banner';
import MainArticle from './MainArticle';

function Insights({ content: data }) {
  const articles = _get(data, 'articles');
  const mainBanners = _get(data, 'main-banners', []);
  const sideBanners = _get(data, 'side-banners', []);
  const sideBanners2 = _get(data, 'side-banners-2', []);

  const { t } = useTranslation();

  return (
    <section className="mb-4 topics" id="topics">
      <div className="row">
        <div className="col-12">
          <div className="container-xxl bg-secondary-light s2s-section-bg mb-3 rounded">
            <div className="section-title section-title-no-line mb-3 mx-3">
              <h2 className="title-lg">
                {t('insights.title')}
              </h2>
            </div>
            <div className="row mb-3 mx-1 x-max">
              <div className="col-lg-9">
                <MainArticle
                  articles={articles}
                />
                <BannerList content={mainBanners} isHorizontal />
              </div>
              <div className="col-lg-3 col-dx">
                <BannerList content={sideBanners} />
                <BannerList content={sideBanners2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Insights;
