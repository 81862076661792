// import _size from 'lodash/size';
import PrettyDate from '../PrettyDate';

function CardFooter(props) {
  const { size, text, date } = props;

  // const shorttext = _size(text) >= 125
  //   ? `${text.substring(0, 123)}...`
  //   : text;

  return (
    <>
      {(size !== 'sm' && text) && (
        <p className="card-text">
          {text}
        </p>
      )}
      <div className="text-xs text-uppercase text-primary mb-3">
        <PrettyDate date={date} />
      </div>
    </>
  );
}

export default CardFooter;
