import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DynamicPagedContent from '../components/DynamicPagedContent';
import PageTitle from '../components/PageTitle';

function Archive(props) {
  const {
    api = 'articles',
    layout = 'article_list_vertical',
    title,
  } = props;
  const {
    langId,
  } = useParams();
  // we can read the value from the query string
  const [searchParams] = useSearchParams();
  const term = searchParams.get('term');
  const arg = searchParams.get('arg') || '';

  const { t } = useTranslation();

  return (
    <>
      <PageTitle text={title} />
      {arg && (<h1>{arg}</h1>)}
      {(term) && (
        <h2 className="title-lg mb-5">
          { t('results_for')}
          {' '}
          {term}
        </h2>
      )}
      <DynamicPagedContent
        apiUrl={`api/${langId}/layout/${layout}`}
        injectUrl={`${api}/${arg}`}
        apiUrlParams={term ? `search=${term}` : null}
        langId={langId}
      />
    </>
  );
}

export default Archive;
