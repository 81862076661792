import _startsWith from 'lodash/startsWith';
import css from './YoutubeEmbed.module.css';

function YoutubeEmbed({ id }) {
  const baseUrl = (_startsWith(id, 'http'))
    ? ''
    : 'https://www.youtube.com/embed/';

  return (
    <div className={`${css.videoResponsive} mb-3 rounded`}>
      <iframe
        width="920"
        height="520"
        src={`${baseUrl}${id}?autoplay=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}

export default YoutubeEmbed;
