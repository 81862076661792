import _map from 'lodash/map';
import GalleryCard from './Card';

function GalleryCardList({ cards }) {
  if (!cards) {
    return null;
  }
  return (
    <div className="row mb-3">
      {_map(cards, ({
        title, slug, image, id, created_at: createdAt, published_at: publishedAt,
      }) => (
        <div className="col-md-4" key={id}>
          <GalleryCard
            title={title}
            slug={slug}
            id={id}
            image={image?.url}
            desc={image?.description}
            date={publishedAt ?? createdAt}
          />
        </div>
      ))}
    </div>
  );
}

export default GalleryCardList;
