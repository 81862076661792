import _isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import Debug from './Debug';
import BannerList from './Banner'; // lista di banner
import BannerSingle from './Banner/Single'; // lista di banner
import BannerCarousel from './Banner/Carousel'; // lista di banner dove immagine e link cambiano a tempo
import ArticleList from './ArticleList'; // lista articoli
import ArticleContent from './ArticleContent'; // contenuto pagina articolo singolo
import Widget from './Widget'; // category + lista articoli senza immagini
import Events from './Events'; // sezione eventi (con titolo e lista eventi ecc)
import Topics from './Topics'; // sezione topics
import MainArticle from './MainArticle'; // articolo in evidenza con lista articoli
import HtmlEmbed from './HtmlEmbed'; // elemento HTML purificato
import SectionHeader from './SectionHeader'; // titolo di sezione (titolo + link)
import Specials from './Specials'; // sezione specials
import SpecialList from './SpecialList'; // lista elementi special
import TagLinks from './TagLinks'; // lista di tag
import Message from './Message';
import CommentElement from './CommentElement'; // lascia un commento + lista commenti
import VideoCardList from './Video/CardList'; // video card list
import VideoSection from './Video'; // youtube embed + video card list
import VideoElement from './Video/Element'; // signle video element
import GalleryCardList from './Gallery/CardList'; // gallery card list
import Carousel from './Carousel'; // carousel
import Gallery from './Gallery'; // Sezione Gallery (blu) carousel + gallery card list
import Iframe from './Iframe'; // da usare per podcast ecc
import EventListElement from './EventListElement'; // event list
import SectionPodcast from './Podcast'; // sezione pocast (titolo + link) + podcast card list
import PodcastList from './Podcast/List'; // podcast card list
import PageTitle from './PageTitle'; // a simple page title

import config from '../config';
import DownloadButton from './DownloadButton';
import Insights from './Insights';
import ShareLinks from './ShareLinks';

const TYPES = {
  attachment: 'attachment',
  mainArticle: 'mainArticle',
  banner: 'banner',
  bannerList: 'banner-list',
  bannerCarousel: 'banner-carousel',
  articleList: 'article-list',
  articleListMostRead: 'article-list-most-read',
  articleContent: 'article-content',
  carousel: 'carousel',
  comment: 'comment',
  html: 'html',
  widget: 'widget',
  gallery: 'gallery',
  video: 'video',
  videoElement: 'video-element',
  podcast: 'podcast',
  iframe: 'iframe',
  events: 'events',
  topics: 'topics',
  insights: 'insights',
  categoryTitle: 'category-title',
  specials: 'specials',
  specialList: 'specialList',
  tagLinks: 'tags',
  shareButtons: 'share-buttons',
  pageTitle: 'page-title',
  photogalleries: 'gallery-list',
  videos: 'video-list',
  podcastList: 'podcast-list',
  eventList: 'event-list',
  noElements: 'no-elements',
  notFound: 'not-found',
  // Nascosti per cambio configurazione / cambio elementi sparsi nel template esterno
  manchetteLeft: 'manchette-left',
  manchetteRight: 'manchette-right',
  translatedUrl: 'translated-url',
};

function Element(props) {
  const {
    type,
    isSide,
    content,
    options = {},
  } = props;

  if (_isEmpty(content)) {
    if (config.ENV !== 'production' && config.ENV !== 'development') {
      console.info(`skipped a ${type} element for his missing content`, {
        type,
        isSide,
        content,
        options,
      });
    }
    return null;
  }

  switch (type) {
    case TYPES.attachment: {
      return (
        <DownloadButton url={content} />
      );
    }
    case TYPES.articleList: {
      const {
        isHorizontal,
        titleSizes,
        sizes,
      } = options;

      return (
        <ArticleList
          articles={content}
          titleSizes={titleSizes || sizes}
          sizes={sizes}
          isHorizontal={isHorizontal}
        />
      );
    }

    case TYPES.articleContent: {
      const {
        title,
        meta_title,
        content: articleContent,
        created_at: createdAt,
        published_at: publishedAt,
        image,
        subtitle: abstract,
        meta_description,
        areas,
        subscription,
        forbidden,
        advertorial,
        highlight,
      } = content;

      return (
        <ArticleContent
          title={title}
          meta_title={meta_title}
          meta_description={meta_description}
          content={articleContent}
          date={publishedAt ?? createdAt}
          image={image}
          abstract={abstract}
          areas={areas}
          subscription={subscription}
          advertorial={advertorial}
          highlight={highlight}
          forbidden={forbidden}
        />
      );
    }

    case TYPES.comment: {
      const { comments } = content;

      return (
        <CommentElement
          comments={comments}
        />
      );
    }

    case TYPES.mainArticle: {
      const { mainCard, sideCard } = content;
      return (
        <MainArticle
          mainCard={mainCard}
          sideCard={sideCard}
        />
      );
    }

    case TYPES.bannerList: {
      const {
        isHorizontal = !isSide,
      } = options;

      return (
        <BannerList content={content} isHorizontal={isHorizontal} />
      );
    }

    case TYPES.bannerCarousel: {
      const {
        timer = 5000,
      } = options;

      return (
        <BannerCarousel content={content} timer={timer} />
      );
    }

    case TYPES.banner: {
      return (
        <BannerSingle content={[content]} />
      );
    }

    case TYPES.carousel: {
      return (
        <Carousel images={content} />
      );
    }

    case TYPES.html: {
      return (
        <HtmlEmbed content={content} />
      );
    }

    case TYPES.gallery: {
      const {
        id, images, title, slug, cards, leaderboard, published_at: publishedAt,
        // image,
      } = content;
      if (id) {
        return (
          <Gallery
            id={id}
            slug={slug}
            cards={cards}
            date={publishedAt}
            images={images}
            title={title}
            leaderboard={leaderboard}
          />
        );
      }
      if (leaderboard) {
        return <BannerSingle content={[leaderboard]} />;
      }
      return null;
    }

    case TYPES.photogalleries: {
      return (
        <GalleryCardList cards={content} />
      );
    }

    case TYPES.topics: {
      return (
        <Topics
          content={content}
        />
      );
    }

    case TYPES.insights: {
      return (
        <Insights
          content={content}
        />
      );
    }

    case TYPES.video: {
      return (
        <VideoSection
          banners={content.banners}
          main={content.main}
          mainVideo={content.mainVideo}
          sideVideos={content.sideVideos}
          videoImage={content.image}
        />
      );
    }

    case TYPES.videos: {
      return (
        <VideoCardList videos={content} />
      );
    }

    case TYPES.videoElement: {
      const { mainVideo, image } = content;
      return (
        <VideoElement id={mainVideo} image={image} />
      );
    }

    case TYPES.podcast: {
      const { items, title } = content;
      return (
        <SectionPodcast items={items} title={title} />
      );
    }

    case TYPES.podcastList: {
      return (
        <PodcastList items={content} />
      );
    }

    case TYPES.iframe: {
      const { url, title, size } = content;
      return (
        <Iframe url={url} title={title} size={size} />
      );
    }

    case TYPES.events: {
      const {
        events,
        coverImage,
      } = content;
      return (
        <Events events={events} coverImage={coverImage} />
      );
    }

    case TYPES.eventList: {
      return (
        <EventListElement events={content} />
      );
    }

    case TYPES.categoryTitle: {
      const { title, iconHref, className } = content;
      return (
        <SectionHeader
          title={title}
          className={className}
          iconHref={iconHref}
        />
      );
    }
    case TYPES.articleListMostRead:
    case TYPES.widget: {
      if (!content) {
        return null;
      }

      return (
        <Widget
          articles={content}
          category={options.title}
        />
      );
    }

    case TYPES.specials: {
      return (
        <Specials
          articles={content}
        />
      );
    }

    case TYPES.specialList: {
      return (
        <SpecialList data={content} />
      );
    }

    case TYPES.tagLinks: {
      const { links } = content;
      return (
        <TagLinks links={links} />
      );
    }

    case TYPES.shareButtons: {
      return (
        <ShareLinks
          key={content.href}
          imageUrl={content.image?.url}
          description={content.description}
          title={content.title}
          href={content.href}
        />
      );
    }

    case TYPES.pageTitle: {
      const { text } = content;
      return (
        <PageTitle text={text} />
      );
    }

    case TYPES.noElements: {
      return (
        <Message message="no_data" />
      );
    }

    case TYPES.notFound: {
      return (
        <Message message="not_found" showBackToHome />
      );
    }

    case TYPES.manchetteLeft: {
      return null;
    }

    case TYPES.manchetteRight: {
      return null;
    }

    case TYPES.translatedUrl: {
      return null;
    }

    default: {
      if (config.ENV !== 'production' && config.ENV !== 'development') {
        console.info(`unkown ${type} element`, {
          type,
          isSide,
          content,
          options,
        });
      }
      return null;
      // return (
      //   <Debug data={{ type, content, options }} />
      // );
    }
  }
}

export default Element;
