import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import SubscribeButton from '../Button';

function OfferBox(props) {
  const { t } = useTranslation();
  const { langId } = useParams();

  const {
    id,
    duration,
    price,
    priceNoTax,
    originalPrice,
    originalPriceNoTax,
    isTopRequested = false,
  } = props;

  const showtax = langId !== 'it';

  return (
    <div className="col-12 col-sm-6 col-xl-3 mb-3">
      <div className={cx('box', { highligted: isTopRequested })}>
        <div className="box-header">
          <h3 className="duration uppercase">
            {t(duration)}
          </h3>
          {isTopRequested ? (
            <div className="top-requested uppercase">
              {t('top_requested')}
            </div>
          ) : null}
        </div>

        <div className="box-body">

          <h3 className="title-xl">
            {`${showtax ? priceNoTax : price} €`}
          </h3>
          <div>
            {originalPrice && (
              <div className="discount-container">
                <h2>
                  {t('instead')}
                  {' '}
                  <span>
                    {`${showtax ? originalPriceNoTax : originalPrice} €`}

                  </span>
                </h2>
              </div>
            )}
            {showtax && (
              <div className="discount-container">
                (+4% VAT if required)
              </div>
            )}
          </div>

        </div>

        <SubscribeButton id={id} />

      </div>
    </div>

  );
}

export default OfferBox;
