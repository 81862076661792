import _map from 'lodash/map';
import EventElement from './EventElement';

function EventListElement({ data }) {
  return (
    <div className="event-list-element">
      {_map(data, (event) => (
        <EventElement event={event} key={event.id} />
      ))}
    </div>
  );
}

export default EventListElement;
