import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import fetchFn from '../utils/fetchFn';
import Link from '../components/SmartLink';

function Cookies() {
  const { t } = useTranslation();
  const { langId } = useParams();
  const [acceptOthers, setAcceptOthers] = useState(true);

  const acceptCookies = (consent) => {
    fetchFn({
      url: `api/${langId}/config/cookie-consent`,
      method: 'PUT',
      body: { consent },
    }).then(() => {
      localStorage.setItem('__cookies', consent);
      window.location.href = window.location.href.replace('/cookies', '');
    });
  };
  return (
    <div>
      <div className="row justify-content-around align-middle">
        <div className="col-12">
          <p className="mb-0">
            {t('cookies.text_long')}
            {' '}
            <Link href="page/privacy">Privacy Policy</Link>
          </p>
        </div>
        <div className="col-12 mt-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => acceptCookies(true)}
          >
            {t('cookies.accept_button')}
          </button>
        </div>
      </div>
      <hr className="mt-5" />
      <div className="row align-middle mt-5">
        <div className="col-12">
          <p className="mb-0">
            <strong>
              {t('cookies.text_select')}
            </strong>
          </p>
        </div>
      </div>
      <div className="row align-middle mt-3">
        <div className="col-11">
          <p className="mb-0">
            {t('cookies.text_tecnical')}
          </p>
        </div>
        <div className="col-1 text-end">
          <input
            style={{ opacity: 0.5 }}
            type="checkbox"
            checked
            readOnly
          />
        </div>
      </div>
      <div className="row align-middle mt-2">
        <div className="col-11">
          <p className="mb-0">
            {t('cookies.text_others')}
          </p>
        </div>
        <div className="col-1 text-end">
          <input
            type="checkbox"
            checked={acceptOthers}
            onChange={() => (setAcceptOthers(!acceptOthers))}
          />
        </div>
      </div>
      <div className="row justify-content-around align-middle">
        <div className="col-12 mt-4">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => acceptCookies(acceptOthers)}
          >
            {t('cookies.confirm_button')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
