/* eslint-disable react/jsx-props-no-spreading */
import _map from 'lodash/map';
import _size from 'lodash/size';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useAPI from '../hooks/useAPI';
import fetchFn from '../utils/fetchFn';
import { useState } from 'react';

function SubAccountsManagement({ accounts, activePlan, onSuccess = () => { } }) {
  const { t } = useTranslation();
  const { langId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const resetFields = () => {
    reset();
  };

  const [showPassword, setShowPassword] = useState(false);

  const deleteAccount = (account) => {
    fetchFn({
      url: `api/${langId}/users/${account.id}`,
      method: 'DELETE',
    }).then(() => {
      if (onSuccess) {
        resetFields();
        onSuccess({});
      }
    });
  };

  const {
    onSubmit,
    isLoading,
    errorMsg,
  } = useAPI({
    onSuccess: (data) => {
      if (onSuccess) {
        resetFields();
        onSuccess(data);
      }
    },
    url: `api/${langId}/user/add_subuser`,
  });
  const canAddUsers = activePlan && _size(accounts) < 2;

  return (
    <>
      {_map(
        accounts,
        (a, i) => (
          <div className="container-xxl bg-secondary-light s2s-section-bg mb-3 rounded pb-3" key={a.id}>
            <h4>
              {a.name}
              {' '}
              {a.lastname}
            </h4>
            <p>{a.email}</p>
            <div>
              <Button
                className="s2s-btn-accent text-uppercase btn-accent"
                onClick={() => deleteAccount(a)}
              >
                {t('account.delete')}
              </Button>
            </div>
          </div>
        ),
      )}
      {canAddUsers ? (
        <div className="container-xxl bg-secondary-light s2s-section-bg mb-3 rounded pb-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="col-12 col-md-6">
                <Form.Group>
                  <Form.Label>{t('name')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={`${t('name')} (${t('required')})`}
                    {...register('name', { required: true })}
                  />
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-6">
                <Form.Group>
                  <Form.Label>{t('lastname')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={`${t('lastname')} (${t('required')})`}
                    {...register('lastname', { required: true })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mt-2">
                  <Form.Label>{t('account.email')}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={`${t('account.email')} (${t('required')})`}
                    {...register('email', { required: true })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mt-2">
                  <Form.Label>{t('account.password')}</Form.Label>
                  <div className="text-form">
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder={`${t('account.password')} (${t('required')})`}
                      {...register('password', { required: true, maxLength: 40 })}
                    />
                    <button
                      type="button"
                      className="show-password"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <span className="material-icons-outlined">
                        {showPassword ? 'visibility_off' : 'visibility'}
                      </span>
                    </button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            {errorMsg ? (
              <Row className="mt-3">
                <span>{t(errorMsg)}</span>
              </Row>
            ) : null}
            <Row className="mt-3">
              <Col>
                <Button
                  type="submit"
                  className="s2s-btn-accent text-uppercase btn-accent"
                  style={{}}
                >
                  {isLoading ? t('loading') : t('account.add_user')}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      ) : null}
    </>
  );
}

export default SubAccountsManagement;
