import AuthUser from '../providers/auth.Provider';
import Authenticate from './Authenticate';

function RequireAuth({ children }) {
  const { isAuth } = AuthUser();

  return (isAuth)
    ? children
    : (
      <div className="row profile">
        <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
          <Authenticate hasAccount>{children}</Authenticate>
        </div>
      </div>
    );
}

export default RequireAuth;
