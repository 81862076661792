/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TransWithLinks from './TransWithLinks';
import AuthUser from '../providers/auth.Provider';

const { Group, Control, Label } = Form;

function Register({ onSuccess }) {
  const {
    register: registerAPI,
    error,
    loading,
  } = AuthUser();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (body) => {
    registerAPI(body).then(onSuccess);
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Label>
        {t('email_address')}
      </Label>

      <Group
        className="mb-3 text-form"
        controlId="formBasicEmail"
      >

        <Control
          type="email"
          placeholder="Enter email"
          {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
        />
        {errors.email && (
          <span className="tag is-danger">
            {t('valid_email')}
          </span>
        )}
      </Group>

      <Label>
        {t('login_form.password')}
      </Label>

      <Group
        className="mb-3 text-form"
        controlId="formBasicPassword"
      >
        <Control
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          {...register('password', { required: true, maxLength: 40 })}
        />
        <button
          type="button"
          className="show-password"
          onClick={() => setShowPassword(!showPassword)}
        >
          <span className="material-icons-outlined">
            {showPassword ? 'visibility_off' : 'visibility'}
          </span>
        </button>
        {errors.password && (
          <span className="tag is-danger">
            {t('password_required')}
          </span>
        )}
      </Group>

      <div className="form-check mb-3">
        <label className="form-check-label" htmlFor="flexCheckDefaultPrivacy">
          <TransWithLinks
            className="form-check-inline "
            i18nKey="accept_privacy_policy"
            links={['page/privacy']}
          />

          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefaultPrivacy"
            {...register('terms', { required: true })}
          />
        </label>
      </div>

      <div className="form-check mb-3">
        <label className="form-check-label" htmlFor="flexCheckDefaultNewsletter">
          {t('subscribe_newsletter')}
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultNewsletter" />
        </label>
      </div>

      <div
        className="login-buttons mb-4"
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {error && <span className="p-2 text-center">{t(error)}</span>}
        <input
          type="submit"
          className="btn btn-accent s2s-btn-accent text-uppercase"
          value={loading ? t('loading') : t('register')}
        />
      </div>
    </Form>

  );
}

export default Register;
