function BadgeS2S() {
  return (
    <span className="badge bg-secondary text-sm s2s-badge-xl mb-4">
      <h3 className="big-tag-s2s">
        S2S+
      </h3>
    </span>
  );
}

export default BadgeS2S;
