import { useTranslation } from 'react-i18next';
import Link from '../SmartLink';

function Title({ title }) {
  const {
    className,
    href,
    text,
    translate = false,
    isRightCol = false,
  } = title;

  const { t } = useTranslation();

  if (isRightCol || !href) {
    return (
      <h3>
        {translate ? t(`titles.${text}`) : text}
      </h3>
    );
  }

  return (
    <h2 className={className}>
      <Link href={href}>
        {translate ? t(`titles.${text}`) : text}
      </Link>
    </h2>
  );
}

export default Title;
