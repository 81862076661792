import { useTranslation } from 'react-i18next';
import Authenticate from '../components/Authenticate';
import Link from '../components/SmartLink';
import UserDataForm from '../components/UserDataForm';
import AuthUser from '../providers/auth.Provider';
import AccountPlan from './AccountPlan';

function Account() {
  const { t } = useTranslation();
  const { user } = AuthUser();
  // const navigate = useNavigate();
  // const [email, setEmail] = useState(user?.email);
  // const { langId } = useParams();

  /*
  useEffect(
    () => setEmail(user.email),
    [user.email],
  );
  */

  /*
  const submitEmail = () => fetchFn({
    url: `api/${langId}/auth/update_email`,
    method: 'POST',
    body: { email },
  });
  */

  return (
    <div className="row profile">
      <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
        <h1 className="mb-5">{t('account.head')}</h1>
        <Authenticate toggle hasAccount>
          <div className="account-panel">
            <div className="mb-5">
              <h2>{t('account.user_data')}</h2>
              <UserDataForm user={user} submitLabel={t('save_changes')} />
            </div>
          </div>

          <div className="account-panel">
            <div className="mb-5">
              <h2>{t('account.password')}</h2>
              <Link
                className="btn btn-accent s2s-btn-accent mt-2"
                href="update_password"
              >
                {t('account.change_password')}
              </Link>
            </div>
          </div>

          {/*
        <div className="account-panel my-3">
          <h2>{t('account.email')}</h2>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  type="email"
                  placeholder={`${t('email')}`}
                  className="my-3"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col className="my-3">
              <Button onClick={submitEmail} variant="accent">
                {t('account.update')}
              </Button>
            </Col>
          </Row>
        </div>
        */}
          {user?.level && user?.level > 0
            ? (
              <AccountPlan />
            ) : null}
        </Authenticate>
      </div>
    </div>
  );
}

export default Account;
