import _map from 'lodash/map';
import cx from 'classnames';
import { useRef, useState } from 'react';
import Image from '../Image';

function Carousel({ images }) {
  const [isActive, setIsActive] = useState(1);

  const thumbnailRef = useRef();

  function scrollLeft() {
    const elem = thumbnailRef.current;
    const leftOrig = elem.scrollLeft;
    let left = elem.scrollLeft;

    const id = setInterval(() => {
      left -= 10;

      elem.scrollLeft = left;

      if (left < leftOrig - 313) {
        clearInterval(id);
      }
    }, 20);
  }

  function scrollRight() {
    const elem = thumbnailRef.current;
    const leftOrig = elem.scrollLeft;
    let left = elem.scrollLeft;

    const id = setInterval(() => {
      left += 10;

      elem.scrollLeft = left;

      if (left >= leftOrig + 313) {
        clearInterval(id);
      }
    }, 20);
  }

  return (
    <div className="carousel slide carousel-fade mb-3" data-mdb-ride="carousel">
      <div className="carousel-inner mb-3 shadow-1-strong rounded-3">
        {_map(images, ({ url_original: src, description: label }, index) => {
          const active = isActive === index + 1;
          const className = cx('carousel-item', { active });
          return (
            <div key={`${src}-${index + 1}`} className={className}>
              <Image url={src} className="d-block w-100" alt={label} />
            </div>
          );
        })}
      </div>

      <div className="carousel-thumbnails-wrapper">
        <button
          className="carousel-control-prev"
          type="button"
          onClick={scrollLeft}
        >
          <span className="material-icons-outlined">arrow_back_ios</span>
        </button>

        <div className="carousel-thumbnails" ref={thumbnailRef}>
          {_map(images, ({ url: src, description: label }, index) => {
            const active = isActive === index + 1;
            return (
              <button
                key={src}
                type="button"
                className={cx('carousel-thumbnail', { active })}
                onClick={() => setIsActive(index + 1)}
                aria-current="true"
                aria-label={`Slide ${index}`}
              >
                <div className="d-block w-100 shadow-1-strong rounded img-fluid">
                  <Image url={src} className="d-block w-100 h-100 br-8" alt={label} />
                </div>
              </button>
            );
          })}
        </div>
        <button
          className="carousel-control-next"
          type="button"
          onClick={scrollRight}
        >
          <span className="material-icons-outlined">arrow_forward_ios</span>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
