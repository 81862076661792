import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect, useState } from 'react';
import PrettyDateRange from './PrettyDateRange';

function HeaderEvents(props) {
  const { t } = useTranslation();

  const [leftOffset, setLeftOffset] = useState(0);
  const [direction, setDirection] = useState(1);
  const [interv, setInterv] = useState(2000);

  // const events = [
  //   {
  //     "date_from": "2024-05-09",
  //     "date_to": "2024-05-12",
  //     "title": "Ship2Sport – The TranSport Games",
  //     "url": "https:\/\/www.ship2sport.it\/",
  //     "location": ""
  //   },
  //   // {
  //   //   "date_from": "2024-05-09",
  //   //   "date_to": "2024-05-12",
  //   //   "title": "Ship2Sport – The TranSport",
  //   //   "url": "https:\/\/www.ship2sport.it\/",
  //   //   "location": ""
  //   // },
  //   // {
  //   //   "date_from": "2024-05-09",
  //   //   "date_to": "2024-05-12",
  //   //   "title": "Ship2Sport – The TranSport",
  //   //   "url": "https:\/\/www.ship2sport.it\/",
  //   //   "location": ""
  //   // }
  // ];

  const {
    events,
  } = props;

  const list = useRef(null);
  const cont = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!list.current.children) {
        return;
      }

      // let innerSize = list.current.children ? Array.from(list.current.children).reduce((sum, c) => {
      //   console.log(c.offsetWidth)
      //   return sum + c.offsetWidth + 15;
      // }, 0) : 0;
      let newOffset = 0;

      if (direction === 1) {
        const undirectedLeftOffset = -1 * leftOffset;

        let totalSize = 0;
        let i = 0;
        const children = Array.from(list.current.children);
        // console.log(cont.current.offsetWidth);
        // console.log('LO ' + undirectedLeftOffset);
        for (; i < children.length; i++) {
          const c = children[i];
          if (newOffset <= undirectedLeftOffset) {
            newOffset += c.offsetWidth + 15; // margin
            // console.log('ADD TO' + newOffset);
          }
          totalSize += c.offsetWidth + 15;
        }
        // totalSize -= 15; // last margin
        // console.log('T ' + totalSize);
        // console.log('Ii ' + newOffset);

        if ((totalSize - 15) > cont.current.offsetWidth) {
          // Alla fine
          // massimo offset
          const maxNewOffset = totalSize - 15 - cont.current.offsetWidth;
          if (newOffset >= maxNewOffset) {
            newOffset = maxNewOffset;
            if (newOffset < 0) {
              newOffset = 0;
            }
            setDirection(0);
          }
        } else {
          newOffset = 0;
        }
        // console.log('Ia ' + newOffset);

        if (newOffset && undirectedLeftOffset === newOffset) {
          newOffset = 0;
        }
      } else {
        setDirection(1);
      }

      // console.log(list.current.children);
      // console.log(newOffset);
      // console.log(list.current.offsetWidth);
      setInterv(12000);
      setLeftOffset(-1 * newOffset);
    }, interv);
    return () => {
      clearInterval(interval);
    };
  }, [leftOffset, direction]);

  if (!events.length) {
    return null;
  }

  return (
    <section id="topBarEvents">
      <div className="container-xxl">
        <div className="row">
          <div className="eventi-label">
            {t('big_events')}
          </div>

          <div className="col-12">
            <div className="bar-scroll-container" ref={cont}>
              <div className="bar-scroll-content" style={{ left: leftOffset }}>
                <ul ref={list}>
                  {events && (
                    _map(events, (e, i) => {
                      const date = `${e.location ? `${e.location}, ` : ''}${PrettyDateRange(e.date_from, e.date_to)}`;
                      return (
                        <li key={i}>
                          {date}
                          {' '}
                          {e.title}
                          <a className="btn btn-sm btn-accent s2s-btn-accent" href={e.url} target="_blank" rel="noreferrer">
                            {t('visit_website')}
                            <span className="material-icons-outlined">east</span>
                          </a>
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  );
}

export default HeaderEvents;
