import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { AuthProvider } from './providers/auth.Provider';
import { ConfProvider } from './providers/config.Provider';
import App from './App';
import './style/s2s-style.scss';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-KN3FQMTS'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ErrorBoundary>
      <ConfProvider>
        <AuthProvider>
          <CookiesProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </CookiesProvider>
        </AuthProvider>
      </ConfProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
