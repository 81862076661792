import _get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFetch from '../hooks/useFetch';
import DynamicContent from '../components/DynamicContent';
import Section from '../layouts/Section';
import Configuration from '../providers/config.Provider';

function StaticPage(props) {
  const {
    Main = null,
    title,
    layout = 'static_page',
    bannersFor = null,
  } = props;
  const {
    langId,
  } = useParams();
  const { t } = useTranslation();

  const {
    updateFromTemplate,
  } = Configuration();

  const { data } = useFetch({
    method: 'POST',
    url: `api/${langId}/layout/${layout}`,
    body: {
      bannersFor,
    },
    then: (d) => {
      updateFromTemplate(d);
    },
  });
  const top = _get(data, 'layout.top', null);
  const side = _get(data, 'layout.side', null);
  const bottom = _get(data, 'layout.bottom', null);

  return (
    <>
      <h1 className="mb-5">
        {t(title)}
      </h1>
      <Section side={side ? <DynamicContent data={[[{ main: side }]]} isSide /> : null}>
        {top ? <DynamicContent data={[[{ main: top }]]} /> : null}
        <div className="mb-5">
          <Main />
        </div>
        {bottom ? <DynamicContent data={[[{ main: bottom }]]} /> : null}
      </Section>
    </>
  );
}

export default StaticPage;
