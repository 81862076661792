import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import Link from './SmartLink';

import css from './Specials.module.css';
import Image from './Image';

function Specials(props) {
  const {
    articles,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="specials">
      <div className="section-title section-title-col-dx mb-3">
        <h3>
          {t('specials')}
        </h3>
      </div>

      <div className="row mb-3">
        {(_map(articles, ({
          title,
          id,
          image
        }) => (

          <div className="col-12 col-md-4 col-lg-12" key={title}>
            <div
              className={css.listitem}
            >
              <Image
                url={image?.url}
                alt={image?.description}
                className={css.image}
              />
              <div className="">
                <h4 className="title-xs text-md">
                  <Link href={`specials/${id}`}>
                    {title}
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        ))
        )}
      </div>
    </div>

  );
}

export default Specials;
