import _isEmpty from 'lodash/isEmpty';
import createSlug from '../utils/createSlug';
import Card from './Card';

function MainArticle(props) {
  const { articles = [] } = props;
  const [
    main = {},
    side1 = {},
    side2 = {},
  ] = articles;

  return (
    <div className="mb-3 row">
      {!_isEmpty(main) ? (
        <div className="col-12 col-sm-8 big-article">
          <Card
            size="full"
            text={main.summary}
            date={main.created_at}
            imgAlt={main.image?.alt}
            imgSrc={main.image?.url}
            href={`article/${createSlug(main.title)}/${main.id}`}
            title={main.title}
            titleSize={main.title_size || 'xl'}
            tag={main.tag}
            isHorizontal={main.isHorizontal}
            categories={main.categories}
            subscription={main.subscription}
            advertorial={main.advertorial}
            highlight={main.highlight}
          />
        </div>
      ) : null}
      {!_isEmpty(side1) || !_isEmpty(side2)
        ? (
          <div className="col-12 col-sm-4 small-article">
            {!_isEmpty(side1)
              ? (
                <Card
                  size="full"
                  date={side1.created_at}
                  imgAlt={side1.image?.alt}
                  imgSrc={side1.image?.url}
                  href={`article/${createSlug(side1.title)}/${side1.id}`}
                  title={side1.title}
                  titleSize={side1.title_size || 'sm'}
                  tag={side1.tag}
                  isHorizontal={side1.isHorizontal}
                  categories={side1.categories}
                  subscription={side1.subscription}
                  advertorial={side1.advertorial}
                  highlight={side1.highlight}
                />
              )
              : null}
            {!_isEmpty(side2)
              ? (
                <Card
                  size="full"
                  date={side2.created_at}
                  imgAlt={side2.image?.alt}
                  imgSrc={side2.image?.url}
                  href={`article/${createSlug(side2.title)}/${side2.id}`}
                  title={side2.title}
                  titleSize={side2.title_size || 'sm'}
                  tag={side2.tag}
                  isHorizontal={side2.isHorizontal}
                  categories={side2.categories}
                  subscription={side2.subscription}
                  advertorial={side2.advertorial}
                  highlight={side2.highlight}
                />
              )
              : null}
          </div>
        )
        : null}
    </div>

  );
}

export default MainArticle;
