import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Layout from './routes/Layout';
import HomePage from './routes/HomePage';
import Login from './routes/Login';
import Logout from './routes/Logout';
import i18nInit from './i18n/i18n';
import Archive from './routes/Archive';
import Category from './routes/Category';
import Subcategory from './routes/Subcategory';
import Demo from './routes/Demo';
import Subscribe from './routes/Subscribe';
import Subscribe1 from './routes/Subscribe1';
import Subscribe2 from './routes/Subscribe2';
import Subscribe3 from './routes/Subscribe3';
import Subscribe4 from './routes/Subscribe4';
import Page from './routes/Page';
import RedirectToHome from './routes/RedirectToHome';
import NotFound from './routes/NotFound';
import Account from './routes/Account';
import Events from './routes/Events';
import Newsletter from './routes/Newsletter';
import StaticPage from './routes/StaticPage';
import Magazine from './routes/Magazine';
import Specials from './routes/Specials';
import useScroll from './hooks/useScroll';
import UpdatePassword from './routes/UpdatePassword';
import ResetPassword from './routes/ResetPassword';
import RequireAuth from './components/RequireAuth';
import Cookies from './routes/Cookies';
import Slug from './routes/Slug';

i18nInit();

function App() {
  useScroll();
  const { t } = useTranslation();

  // Settaggio meta generici
  document.querySelector('meta[property="og:title"]').setAttribute('content', 'Ship2Shore');
  document.querySelector('meta[property="og:description"]').setAttribute('content', t('meta.description'));
  document.querySelector('meta[property="og:type"]').setAttribute('content', 'website');
  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location.href);
  document.querySelector('meta[property="og:image"]').setAttribute('content', t('meta.logo'));
  document.querySelector('meta[property="og:image:secure_url"]').setAttribute('content', t('meta.logo'));
  document.querySelector('meta[property="og:image:alt"]').setAttribute('content', 'Ship2Shore Logo');

  const routes = [
    {
      path: ['article', ':foo', ':id'],
      Component: Page,
      props: { layout: 'articles_single', api: 'articles' },
    },
    {
      path: ['article', ':id'],
      Component: Page,
      props: { layout: 'articles_single', api: 'articles/slug' },
    },
    {
      path: ['videos'],
      Component: Archive,
      props: { title: 'videos', layout: 'video_list', api: 'videos' },
    },
    {
      path: ['video', ':foo', ':id'],
      Component: Page,
      props: { layout: 'videos_single', api: 'videos' },
    },
    {
      path: ['video', ':id'],
      Component: Page,
      props: { layout: 'videos_single', api: 'videos/slug' },
    },
    {
      path: ['podcasts'],
      Component: Archive,
      props: { title: 'podcasts', layout: 'podcast_list', api: 'podcasts' },
    },
    {
      path: ['podcast', ':foo', ':id'],
      Component: Page,
      props: { layout: 'podcasts_single', api: 'podcasts' },
    },
    {
      path: ['podcast', ':id'],
      Component: Page,
      props: { layout: 'podcasts_single', api: 'podcasts/slug' },
    },
    {
      path: ['photogalleries'],
      Component: Archive,
      props: { title: 'photogalleries', layout: 'gallery_list', api: 'photogalleries' },
    },
    {
      path: ['photogallery', ':foo', ':id'],
      Component: Page,
      props: { layout: 'galleries_single', api: 'photogalleries' },
    },
    {
      path: ['photogallery', ':id'],
      Component: Page,
      props: { layout: 'galleries_single', api: 'photogalleries/slug' },
    },
    {
      path: ['page', ':id'],
      Component: Page,
      props: { layout: 'page', api: 'pages/alias' },
    },
    {
      path: ['magazines'],
      Component: StaticPage,
      props: { Main: Magazine, title: 'magazines', bannersFor: 'magazines' },
    },
    {
      path: ['magazines', ':id'],
      Component: Page,
      props: { layout: 'magazines_single', api: 'magazines' },
    },
    {
      path: ['specials'],
      Component: StaticPage,
      props: { Main: Specials, title: 'specials', bannersFor: 'specials' },
    },
    {
      path: ['specials', ':id'],
      Component: Page,
      props: { layout: 'specials_single', api: 'specials' },
    },
    {
      path: ['events'],
      Component: StaticPage,
      props: { Main: Events, title: 'events', bannersFor: 'events' },
    },
    {
      path: ['events', ':id'],
      Component: Page,
      props: { layout: 'events_single', api: 'events' },
    },
    {
      path: ['category', ':categoryId'],
      Component: Category,
    },
    {
      path: ['category', ':categoryId', ':subcategoryId'],
      Component: Subcategory,
    },
    {
      path: ['search'],
      Component: Archive,
      props: { searching: true },
    },
    {
      path: ['archive'],
      Component: Archive,
    },
    {
      path: ['subscribe'],
      Component: Subscribe,
    },
    {
      path: ['subscribe', '1'],
      Component: Subscribe1,
    },
    {
      path: ['subscribe', '2'],
      Component: Subscribe2,
      requireAuth: true,
    },
    {
      path: ['subscribe', '3'],
      Component: Subscribe3,
      requireAuth: true,
    },
    {
      path: ['subscribe', '4'],
      Component: Subscribe4,
      requireAuth: true,
    },
    {
      path: ['newsletter'],
      Component: Newsletter,
    },
    {
      path: ['demo'],
      Component: Demo,
    },
    {
      path: ['profile'],
      Component: Account,
      requireAuth: true,
    },
    {
      path: ['update_password'],
      Component: UpdatePassword,
      requireAuth: true,
    },
    {
      path: ['reset_password', ':code'],
      Component: ResetPassword,
    },
    {
      path: ['login'],
      Component: Login,
    },
    {
      path: ['logout'],
      Component: Logout,
    },
    {
      path: ['cookies'],
      Component: StaticPage,
      props: { Main: Cookies, title: 'cookies.title', bannersFor: 'cookies' },
    },
  ];

  return (
    <Routes>
      <Route path="/" element={<RedirectToHome />} />
      <Route path="/:langId" element={<Layout />}>
        <Route index element={<HomePage />} />

        {routes.map((route) => {
          const props = route.props ?? {};
          let component = <route.Component {...props} />;
          if (route.requireAuth) {
            component = <RequireAuth><route.Component {...props} /></RequireAuth>;
          }
          const strPath = route.path.join('/');
          return (
            <Route key={strPath} path={strPath} element={component} />
          );
        })}

        <Route path=":slug" element={<Slug routes={routes} />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
