import { useTranslation } from 'react-i18next';
import getImgSrc from '../../utils/getImgSrc';
import Link from '../SmartLink';

function CardImage(props) {
  const {
    href,
    src,
    alt,
    square = false,
    isHorizontal,
    highlight = false,
    advertorial = false,
  } = props;
  const style = {
    minWidth: isHorizontal ? '200px' : 0,
  };
  const { t } = useTranslation();

  const classSquare = square ? 'square-img' : '';

  return (
    <Link href={href}>
      <div className={`img-container ${classSquare}`}>
        <img
          style={style}
          src={getImgSrc(src)}
          alt={alt}
          className="card-img-top"
        />
        <div className="sts-badge-container">
          {highlight ? <span className="sts-subhead">{highlight}</span> : null}
          {advertorial ? <span className="sts-subhead sts-advertorial">{t('advertorial')}</span> : null}
        </div>
      </div>
    </Link>
  );
}

export default CardImage;
