import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoginPage from '../components/Login';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSuccess = () => {
    // on submit succes return to previous page
    navigate(-1);
  };

  return (
    <div className="col-md-4 m-auto mb-5">
      <div className="title-lg card-title">
        <h3>
          {t('login')}
        </h3>
      </div>
      <LoginPage onSuccess={handleSuccess} />
    </div>
  );
}

export default Login;
