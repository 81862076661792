import { useParams } from 'react-router-dom';
import RequireAuth from '../components/RequireAuth';
import Page from './Page';

function Slug({ routes }) {
  const { langId, slug } = useParams();
  const { rewrites } = window;

  let pathArr = rewrites?.[langId]?.[slug]?.split('/');
  let route;
  if (pathArr) {
    pathArr = pathArr.filter((element) => element !== '');

    const [filteredRoutes] = routes.filter((r) => (
      r.path.length === pathArr.length && r.path.every((e, i) => e[0] === ':' || e === pathArr[i])
    ));
    route = filteredRoutes;
  }

  if (!route) {
    return <Page layout="articles_single" api="articles/slug" id={slug} />;
    // return <NotFound />;
  }

  route.props = route.props ?? {};

  route.path.forEach((element, index) => {
    if (element[0] === ':') {
      route.props[element.substring(1, element.length)] = pathArr[index];
    }
  });

  if (route.requireAuth) {
    return (
      <RequireAuth><route.Component {...route.props} /></RequireAuth>
    );
  }

  return <route.Component {...route.props} />;
}

export default Slug;
