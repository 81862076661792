/* eslint-disable react/jsx-props-no-spreading */
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import TransWithLinks from '../components/TransWithLinks';
import useAPI from '../hooks/useAPI';
import Message from '../components/Message';

const { Group, Control } = Form;

function Newsletter() {
  const { t } = useTranslation();
  const { langId } = useParams();
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // TODO check endpoint
  const {
    onSubmit,
    isLoading: loading,
    errorMsg: error,
  } = useAPI({
    requireAuth: false,
    onSuccess: () => {
      setSuccess(true);
      dataLayer.push({ 'event': 'iscrizione_nl' });
    },
    url: `api/${langId}/newsletter`,
  });

  if (success) {
    return (
      <Message message="newsletter_page.subscription_success" showBackToHome />
    );
  }

  return (
    <Loader error={error} loading={loading}>
      <div
        className="subscription-page mb-3"
        style={{
          width: '50%',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="subscribe" style={{ textAlign: 'center' }}>
          <h1 className="mb-3">
            {t('newsletter_page.subscribe_title')}
          </h1>
          <h2 className="mb-5">
            {t('newsletter_page.subscribe_subtitle')}
          </h2>

          <p className="mb-5">
            {t('newsletter_page.subscribe_info')}
          </p>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Group
            className="mb-3 text-form"
            controlId="formBasicEmail"
          >

            <Control
              type="email"
              placeholder={t('email_address')}
              {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
            />
            {errors.email && (
              <span className="tag is-danger">
                {t('valid_email')}
              </span>
            )}
          </Group>

          <div className="mb-3 mt-4" style={{ display: 'flex' }}>
            <div className="form-check mb-3">
              <label className="form-check-label" htmlFor="flexRadioDefaultIt">
                <span className="form-check-inline">
                  {t('italian')}
                </span>

                <input
                  className="form-check-input"
                  type="radio"
                  value="italiano"
                  id="flexRadioDefaultIt"
                  {...register('language', { required: true })}
                />
              </label>
            </div>
            <div className="form-check mb-3">
              <label className="form-check-label" htmlFor="flexRadioDefaultEn">
                <span className="form-check-inline">
                  {t('english')}
                </span>

                <input
                  className="form-check-input"
                  type="radio"
                  value="inglese"
                  id="flexRadioDefaultEn"
                  {...register('language', { required: true })}
                />
              </label>
            </div>
          </div>

          <div className="form-check mb-3">
            <label className="form-check-label" htmlFor="flexCheckDefaultPrivacy">
              <TransWithLinks
                className="form-check-inline "
                i18nKey="accept_privacy_policy"
                links={['page/privacy']}
              />

              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefaultPrivacy"
                {...register('privacy', { required: true })}
              />
            </label>
          </div>
          <div className="form-check mb-3">
            <label className="form-check-label" htmlFor="flexCheckDefaultTerms">
              <TransWithLinks
                className="form-check-inline "
                i18nKey="accept_terms"
                links={['page/terms']}
              />

              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefaultTerms"
                {...register('terms', { required: true })}
              />
            </label>
          </div>
          {error && <span className="p-2 text-center">{t(error)}</span>}
          <input
            type="submit"
            className="btn btn-accent s2s-btn-accent text-uppercase mt-3"
            style={{ width: '190px', height: '40px' }}
            value={loading ? t('loading') : t('enroll')}
          />

        </Form>
      </div>
    </Loader>
  );
}

export default Newsletter;
