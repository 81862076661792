import dompurify from 'dompurify';

function HtmlEmbed(props) {
  const { content } = props;

  return (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(content),
      }}
      />
    </>
  );
}

export default HtmlEmbed;
