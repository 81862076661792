import _map from 'lodash/map';
import _size from 'lodash/size';
import { useTranslation } from 'react-i18next';
import PrettyDate from './PrettyDate';

function CommentList(props) {
  const { comments } = props;
  const options = { year: 'numeric', hour: '2-digit', minute: '2-digit' };
  const { t } = useTranslation();

  if (!_size(comments)) {
    return null;
  }

  return (
    <>
      <div className="section-title section-title-col-dx mb-4">
        <h3>
          {t('comment.list-title')}
          {' '}
          (
          {_size(comments)}
          ):
        </h3>
      </div>
      {_map(comments, (c) => (
        <div className="comment-box">

          <div className="d-flex" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3 className="text-md">
              {c.user_name}
            </h3>
            <p className="text-sm text-uppercase">
              <PrettyDate date={c.created_at} options={options} />
            </p>
          </div>
          <p className="text-md">
            {c.content}
          </p>
        </div>
      ))}
    </>
  );
}

export default CommentList;
