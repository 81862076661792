import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import fetchFn from '../utils/fetchFn';

function ResetPassword(props) {
  const params = useParams();
  let { code } = props;
  if (code === undefined) {
    code = params.code;
  }
  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [resetPaswordSuccess, setResetPasswordSuccess] = useState(false);

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const { langId } = useParams();

  const { t } = useTranslation();

  const submitPassword = () => {
    if (password !== newPassword) {
      setError(t('new_password_mismatch'));
      return;
    }
    fetchFn({
      url: `api/${langId}/auth/reset_password`,
      method: 'POST',
      body: { password, code },
    }).then(() => {
      setResetPasswordSuccess(true);
    }).catch(() => {
      setError(t('new_password_error'));
    });
  };

  if (resetPaswordSuccess) {
    return (
      <Alert variant="success">
        {t('new_password_set')}
      </Alert>
    );
  }

  return (
    <>
      <h1 className="mb-5">{t('reset_password')}</h1>
      <div style={{ maxWidth: 700, margin: 'auto' }}>

        <div className="account-panel my-3">
          <h2>
            {t('new_password')}
          </h2>
          <Row>
            <Col>
              <Form.Group>
                <div className="text-form">
                  <Form.Control
                    type={showPassword1 ? 'text' : 'password'}
                    placeholder={`${t('password')}`}
                    className="my-3"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="show-password"
                    onClick={() => setShowPassword1(!showPassword1)}
                  >
                    <span className="material-icons-outlined">
                      {showPassword1 ? 'visibility_off' : 'visibility'}
                    </span>
                  </button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="account-panel my-3">
          <h2>
            {t('confirm_new_password')}
          </h2>
          <Row>
            <Col>
              <Form.Group>
                <div className="text-form">
                  <Form.Control
                    type={showPassword2 ? 'text' : 'password'}
                    placeholder={`${t('password')}`}
                    className="my-3"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    className="show-password"
                    onClick={() => setShowPassword2(!showPassword2)}
                  >
                    <span className="material-icons-outlined">
                      {showPassword2 ? 'visibility_off' : 'visibility'}
                    </span>
                  </button>
                </div>
              </Form.Group>
            </Col>
          </Row>
          {error && <div>{error}</div>}
          <Row>
            <Col className="my-3">
              <Button onClick={submitPassword} variant="accent">
                {t('account.update')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
