import i18n from 'i18next';

const availableLocales = {
  it: 'it-IT',
  en: 'en-US',
};

function PrettyDate({ date: rawDate, options: propOptions }) {
  const date = new Date(rawDate);
  const { language } = i18n;

  const options = propOptions || {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const locale = availableLocales[language];

  return (
    date.toLocaleDateString(locale, options)
  );
}

export default PrettyDate;
