import { useTranslation } from 'react-i18next';

function NotFound() {
  const { t } = useTranslation();

  return (
    <div className="inner text-center">

      <h1 className="bigTitle">
        {t('page_not_found')}
      </h1>

      <p>
        {t('oops')}
        <br />
        <br />
        <span>{t('page_not_found_info')}</span>
        <br />
      </p>

    </div>
  );
}

export default NotFound;
