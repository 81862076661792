import _get from 'lodash/get';
import _map from 'lodash/map';
import _floor from 'lodash/floor';
import _clamp from 'lodash/clamp';
import _isFunction from 'lodash/isFunction';
import { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';

import Debug from './Debug';
import Configuration from '../providers/config.Provider';

const MIN_ELEMENTS_SHOWN = 2;
const MAX_ELEMENTS_SHOWN = 5;

function Paginator(props) {
  const {
    PageComponent = Debug,
    getPage,
    dataField = 'layout',
  } = props;
  const [data, setData] = useState(null);
  const [current, setCurrent] = useState(1);
  const [last, setLast] = useState(1);

  const {
    updateFromTemplate,
  } = Configuration();

  const cut = (last > MAX_ELEMENTS_SHOWN);
  const closeToFirst = (current < MAX_ELEMENTS_SHOWN);
  const closeToLast = (last - current < MAX_ELEMENTS_SHOWN);

  // show a max n of element around current
  // ex. max 6 element, current = 123, max = 1001
  // 1 ... 120, 121, 122, [123], 124, 125, 126, ..., 1001
  const links = _map(
    new Array(
      _clamp(
        last,
        MIN_ELEMENTS_SHOWN,
        MAX_ELEMENTS_SHOWN,
      ),
    ).fill(0),
    (_, index) => (
      index + _clamp(
        current - _floor(MAX_ELEMENTS_SHOWN / 2),
        1,
        1 + last - MAX_ELEMENTS_SHOWN,
      )
    ),
  );

  const selectPage = (id) => {
    if (id < 1 || id > last) {
      return;
    }
    window.scrollTo(0, 0);
    setCurrent(id);
  };

  useEffect(
    () => {
      if (!_isFunction(getPage)) {
        console.warn('Paginator.getPage is not a function:', { getPage });
        return;
      }
      getPage(current)
        .then((res) => {
          // Mega tapullo, se tra gli elementi ci sono manchette, aggiorniamo il conf
          updateFromTemplate(res);
          setData(_get(res, dataField, null));
          setLast(_get(res, ['meta', 'last_page'], 1));
        });
    },
    [current, getPage],
  );

  // no need for a pager with 1 page or less
  if (last < MIN_ELEMENTS_SHOWN) {
    return (
      <PageComponent data={data} />
    );
  }

  return (
    <>
      <PageComponent data={data} />

      <Pagination className="py-4">
        {(current !== 1) && <Pagination.Prev onClick={() => selectPage(current - 1)} />}
        {cut && !closeToFirst && (
          <>
            <Pagination.Item onClick={() => selectPage(1)}>
              {1}
            </Pagination.Item>
            <Pagination.Ellipsis />
          </>
        )}
        {_map(
          links,
          (id) => (
            <Pagination.Item
              key={id}
              active={current === id}
              onClick={() => selectPage(id)}
            >
              {id}
            </Pagination.Item>
          ),
        )}
        {cut && !closeToLast && (
          <>
            <Pagination.Ellipsis />
            <Pagination.Item onClick={() => selectPage(last)}>
              {last}
            </Pagination.Item>
          </>
        )}
        {(current !== last) && <Pagination.Next onClick={() => selectPage(current + 1)} />}
      </Pagination>
      {/* <pre>
        debug:
        {JSON.stringify({
          current,
          last,
          cut,
          max: MAX_ELEMENTS_SHOWN,
          closeToFirst,
          closeToLast,
        }, 0, 2)}
      </pre> */}
    </>
  );
}

export default Paginator;
