import { useTranslation } from 'react-i18next';
import BadgeS2S from '../BadgeS2S';

function SubscriptionHeader() {
  const { t } = useTranslation();

  return (
    <div
      className="attiva-abbonamento mb-5"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className="mb-5">
        <BadgeS2S />
      </div>

      <h1>
        {t('subscribe_header')}
      </h1>

      <h2>
        {t('subscribe_subtitle')}

      </h2>
    </div>
  );
}

export default SubscriptionHeader;
