/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _map from 'lodash/map';
import _keys from 'lodash/keys';
import _get from 'lodash/get';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BannerList from './Banner';
import MainArticle from './MainArticle';
import Widget from './Widget';

function Topics({ content: data }) {
  const articles = _get(data, 'topics');
  const topics = _keys(articles);
  const mainBanners = _get(data, 'main-banners', []);
  const sideBanners = _get(data, 'side-banners', []);
  const widget = _get(data, 'widget', {});

  const [index, setIndex] = useState(topics[0] || {});
  const { t } = useTranslation();

  const { category, content } = widget;
  const { title } = category;

  return (
    <section className="mb-4 topics" id="topics">
      <div className="row">
        <div className="col-12">
          <div className="container-xxl bg-secondary-light s2s-section-bg mb-3 rounded">
            <div className="section-title section-title-no-line mb-3 mx-3">
              <h2 className="title-lg">
                {t('topics.title')}
              </h2>
              <div
                className="justify-content-between m-3"
                style={{ display: 'flex', flexWrap: 'wrap' }}
              >
                <ul className="list-group list-group-horizontal flex-wrap">
                  {_map(topics, (id) => (
                    <li key={id}>
                      <Button
                        size="sm"
                        className="text-uppercase"
                        variant={id === index ? 'primary' : 'outline-primary'}
                        onClick={() => setIndex(id)}
                      >
                        #
                        {id}
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="row mb-3 mx-1 x-max">
              <div className="col-lg-9">
                <MainArticle
                  articles={articles[index]}
                />
                <BannerList content={mainBanners} isHorizontal />
              </div>
              <div className="col-lg-3 col-dx">
                <div className="row">
                  <Widget category={title} articles={content} />
                </div>
                <BannerList content={sideBanners} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Topics;
