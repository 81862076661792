import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import Banner from './Banner';
import useWindowSize from '../../hooks/useWindowSize';

const MOBILE_TRESHOLD = 767;

function BannerList({ content, isHorizontal }) {
  const c = isHorizontal ? 'row' : 'column';
  const { width } = useWindowSize();

  if (_isEmpty(_filter(content))) {
    return null;
  }

  return (
    <div className="row mb-3">
      {(_map(content, ({
        // className, href, alt, src,
        id,
        url,
        title,
        image_desktop: imageDesktop,
        image_mobile: imageMobile,
        // slot,
        // created_at,
        // date_from,
        // date_to,
        // language,
        // on_pages,
        // updated_at,
      }, index) => (
        <div className={`col-12 col-sm-4 ${isHorizontal ? '' : 'col-lg-12'}`} key={`${id}-${index}`}>
          <Banner
            key={id}
            href={url}
            alt={title}
            src={(width <= MOBILE_TRESHOLD && imageMobile) ? imageMobile : imageDesktop}
          />
        </div>
      ))
        )}
    </div>
  );
}

export default BannerList;
