import CardBody from './Body';
import CardFooter from './Footer';
import CardImage from './Image';
import getSizeWidth from '../../utils/getSizeWidth';

function Card(props) {
  const {
    size,
    categories,
    text,
    date,
    imgAlt,
    imgSrc,
    href,
    title,
    titleSize,
    imgSquare = false,
    tag,
    dateOnTop = false,
    isHorizontal,
    subscription,
    isExternalLink = false,
    highlight = false,
    advertorial = false,
  } = props;

  const style = {
    cont: {
      flexDirection: isHorizontal ? 'row ' : 'column',
      width: getSizeWidth(size, isHorizontal),
    },
    img: {
      flex: isHorizontal ? 0.5 : 'initial',
      marginRight: isHorizontal ? '20px' : 0,
      width: '100%',
    },
    body: {
      flex: 1,
      width: '100%',
    },
  };

  return (
    <article className="card d-flex flex-wrap" style={style.cont}>
      <div className="mb-3" style={style.img}>
        <CardImage
          isExternalLink={isExternalLink}
          href={href}
          src={imgSrc}
          alt={imgAlt}
          size={size}
          square={imgSquare}
          isHorizontal={isHorizontal}
          advertorial={advertorial}
          highlight={highlight}
        />
      </div>
      <div style={style.body}>
        {dateOnTop && (
          <div className="text-lg text-primary">
            {date}
          </div>
        )}
        <CardBody
          isExternalLink={isExternalLink}
          categories={categories}
          tag={tag}
          title={title}
          size={titleSize || size}
          href={href}
          subscription={subscription}
          isHorizontal={isHorizontal}
        />
        <CardFooter
          text={text}
          date={!dateOnTop && date}
          size={size}
        />
      </div>
    </article>
  );
}

export default Card;
