/* eslint-disable jsx-a11y/control-has-associated-label */
import _map from 'lodash/map';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchFn from '../utils/fetchFn';

function YearMonthSelector(props) {
  const {
    month,
    year,
    setMonth,
    setYear,
    getYearsUrl,
    getMonthsUrl,
  } = props;
  const { t } = useTranslation();
  const [years, setYears] = useState();
  const [months, setMonths] = useState();

  // on component mount, load years
  useEffect(
    () => {
      fetchFn({ url: getYearsUrl }).then(setYears);
    },
    [],
  );

  // on year change, load months
  useEffect(
    () => {
      fetchFn({ url: getMonthsUrl(year) }).then(setMonths);
    },
    [getYearsUrl, year],
  );

  return (
    <div className="bg-secondary-light p-3 p-md-4 rounded">
      <div className="row">
        <Form.Group className="col-12 col-md-6">
          <Form.Label>{t('filter-by-year')}</Form.Label>
          <Form.Select value={year} onChange={(e) => { setYear(e.target.value); setMonth(); }}>
            <option value="">{t('select_year')}</option>
            {_map(
              years,
              (y) => <option key={y} value={y}>{y}</option>,
            )}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-12 col-md-6 mt-3 mt-md-0">
          <Form.Label>{t('filter-by-month')}</Form.Label>
          <Form.Select value={month} onChange={(e) => setMonth(e.target.value)}>
            <option value="">{t('select_month')}</option>
            {_map(
              months,
              (y) => <option key={y} value={y}>{y}</option>,
            )}
          </Form.Select>
        </Form.Group>
      </div>
    </div>
  );
}

export default YearMonthSelector;
