import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import MagazineElement from './MagazineElement';

function MagazineElementList({ data }) {
  if (_isEmpty(data)) {
    return null;
  }
  return (
    <div className="magazine-list-element">
      <div>
        {_map(data, (magazine, index) => (
          <MagazineElement magazine={magazine} key={index} />
        ))}
      </div>
    </div>
  );
}

export default MagazineElementList;
