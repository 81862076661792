import { useTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import Link from './SmartLink';
import getFileSrc from '../utils/getFileSrc';

function MagazineElement({ magazine }) {
  const {
    year,
    month,
    id,
    forbidden,
    attachment,
    title,
  } = magazine;

  const { t } = useTranslation();

  const getRange = () => {
    const range = ` ${year} - ${month} `;
    if (_isEmpty(year) && _isEmpty(month)) {
      return '';
    }
    if (_isEmpty(year)) {
      return month;
    }
    if (_isEmpty(month)) {
      return year;
    }

    return range;
  };

  return (
    <div className="magazine-element">
      <div>
        <span className="material-icons-outlined mx-2">
          description
        </span>
      </div>
      <div className="mx-4">
        <Link
          href={`magazines/${id}`}
          className="d-flex"
        >
          <div className="flex-column">
            <div className="magazine-element-date">
              {getRange()}
            </div>
            <h3 className="title-md">
              {title}
            </h3>
          </div>
        </Link>
        {attachment
          ? (
            <Link
              noPrefix
              href={getFileSrc(attachment)}
              className="mt-3 btn btn-outline-primary"
              restricted={!!forbidden}
            >
              {t('download')}
            </Link>
          )
          : null}
      </div>
    </div>

  );
}

export default MagazineElement;
