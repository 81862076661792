import _map from 'lodash/map';
import OfferBox from './Box';

function Offers({ subscriptions }) {
  return (
    <div className="container-xxl">
      <div className="row mb-4">
        {_map(subscriptions, ({
          id, duration, price, priceNoTax, originalPrice, originalPriceNoTax, isTopRequested,
        }) => (
          <OfferBox
            id={id}
            key={duration}
            duration={duration}
            price={price}
            priceNoTax={priceNoTax}
            originalPrice={originalPrice}
            originalPriceNoTax={originalPriceNoTax}
            isTopRequested={isTopRequested}
          />
        ))}
      </div>
    </div>
  );
}

export default Offers;
