import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import DynamicContent from '../components/DynamicContent';
import Configuration from '../providers/config.Provider';
// import data from '../homepageTest.json';

function HomePage() {
  const { langId } = useParams();
  const homeRef = useRef();

  const {
    homepageHeight,
    updateFromTemplate,
    updateHomepageHeight,
  } = Configuration();

  const { data = {} } = useFetch({
    url: `api/${langId}/layout/homepage_top`,
    method: 'POST',
    then: (d) => {
      updateFromTemplate(d);
    },
  });
  const { data: dataFull = null } = useFetch({
    url: `api/${langId}/layout/homepage`,
    method: 'POST',
  });
  const getHeight = () => {
    const h = homeRef.current ? homeRef.current.clientHeight : 0;
    updateHomepageHeight(h);
  };

  useEffect(() => {
    getHeight();
  }, [dataFull]);

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    window.addEventListener('resize', getHeight);
  }, []);

  return (
    <div style={{ 'minHeight': homepageHeight }} ref={homeRef}>
      {dataFull ? (
        <DynamicContent data={dataFull?.layout} />
      ) : (
        <DynamicContent data={data?.layout} />
      )}
    </div >
  );
}

export default HomePage;
