import { useTranslation } from 'react-i18next';
import SectionHeader from '../SectionHeader/Header';
import PodcastList from './List';

function Podcast(props) {
  const {
    title,
    items = [],
  } = props;
  const { t } = useTranslation();

  return (
    <div className="container-lg bg-secondary-light s2s-section-bg section-podcast mb-4">
      <SectionHeader
        className="mb-4 mx-3 mt-3"
        title={{
          text: t(title),
        }}
        iconHref="podcasts"
      />

      <PodcastList items={items} />

    </div>
  );
}

export default Podcast;
