import { useTranslation } from 'react-i18next';
import ProgressBar from '../components/ProgressBar';
import SubscriptionHeader from '../components/Subscriptions/Header';
// import TransWithLinks from '../components/TransWithLinks';

function Subscribe4() {
  const { t } = useTranslation();

  return (
    <div
      className="subscription-page"
      style={{
        width: '50%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '5rem',
      }}
    >
      <SubscriptionHeader />
      <ProgressBar availableLevels={[1, 2, 3, 4]} currentLevel={4} />

      <div
        className="attiva-abbonamento mb-5"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="d-flex mb-3">
          <span className="material-symbols-rounded final">
            check_circle
          </span>
          <h1>
            {t('completed')}
          </h1>
        </div>

        {/*
        <h2 className="mb-5">
          {t('check_email')}
        </h2>

        <h2>
          <TransWithLinks
            i18nKey="subscribe_page.view_profile"
            links={['profile']}
          />
        </h2>
        */}
      </div>

    </div>
  );
}

export default Subscribe4;
