import { useTranslation } from 'react-i18next';

function RestrictedBadge(props) {
  const { subscription } = props;
  const { t } = useTranslation();

  if (subscription) {
    return (
      <span className="badge bg-secondary text-sm s2s-badge-lg">
        <span className="border-end pe-1 me-1">
          {t('restricted_content')}
        </span>
        S2S+
      </span>
    );
  }
  return null;
}

export default RestrictedBadge;
