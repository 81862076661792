import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import _find from 'lodash/find';

import HtmlEmbed from '../components/HtmlEmbed';
import Subscriptions from '../components/Subscriptions';
import AuthUser from '../providers/auth.Provider';
import Configuration from '../providers/config.Provider';
import TextForm from '../components/Subscriptions/InfoForm';
import useFetch from '../hooks/useFetch';
import PrettyDate from '../components/PrettyDate';

function Subscribe() {
  const { t } = useTranslation();
  const { langId } = useParams();
  const { data } = useFetch({
    url: `api/${langId}/pages/alias/subscription`,
  });

  const {
    isAuth,
    user,
  } = AuthUser();

  const {
    subscriptions,
  } = Configuration().config;

  const selectedPlan = isAuth && user.subscription_plan_id
    ? _find(subscriptions, { id: parseInt(user.subscription_plan_id, 10) })
    : null;
  const activePlan = isAuth && user.subscription_expires
    ? (new Date(user.subscription_expires)).getTime() >= (new Date()).getTime()
    : false;

  const content = _get(data, ['data', 'content'], '');

  let title = t('subscribe_header');
  if (selectedPlan) {
    if (activePlan) {
      title = t('subscribed_header');
    } else {
      title = t('resubscribe_header');
    }
  }

  let subtitle = t('subscribe_subtitle');
  if (activePlan) {
    subtitle = `${t('account.expire_date')} ${PrettyDate({ date: new Date(user.subscription_expires) })}`;
  }

  return (
    <div className="container-xxl page-wrapper">
      <div className="subscribe">
        <Subscriptions
          title={title}
          subtitle={subtitle}
        />

        <div className="more-info-wrapper">
          <div className="offers-description">
            <HtmlEmbed content={content} />
          </div>

          <TextForm />
        </div>

      </div>
    </div>
  );
}

export default Subscribe;
