import _isEmpty from 'lodash/isEmpty';
// import _capitalize from 'lodash/capitalize';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Configuration from '../providers/config.Provider';
import Image from './Image';
import useWindowSize from '../hooks/useWindowSize';

const MOBILE_TRESHOLD = 767;

function BannerPopup() {
  const { popup } = Configuration().config;
  const { width } = useWindowSize();
  const isMobile = width < MOBILE_TRESHOLD;

  const {
    image_desktop: imgDesktop,
    image_mobile: imgMobile,
    url,
    // title,
    id,
  } = popup ?? {};

  const image = isMobile && imgMobile ? imgMobile : imgDesktop;

  const { localStorage } = window;
  const isAccepted = localStorage.getItem(`popup_${id}`);
  const [isVisible, setIsVisible] = useState(!isAccepted);

  if (_isEmpty(popup) || isAccepted) {
    return null;
  }

  const onClose = () => {
    localStorage.setItem(`popup_${id}`, 'accepted');
    setIsVisible(false);
  };

  return (
    <Modal
      className="rounded-3"
      show={isVisible}
      onHide={onClose}
      backdrop="static"
      keyboard
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <a
          href={url}
          target="_blank"
          rel="nofollow noreferrer"
          onClick={onClose}
        >
          <Image
            className="d-block w-100"
            url={image}
          />
        </a>
      </Modal.Body>
    </Modal>
  );
}

export default BannerPopup;
