/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _startsWith from 'lodash/startsWith';
import _isEmpty from 'lodash/isEmpty';
import { Link, useParams } from 'react-router-dom';
import AuthUser from '../providers/auth.Provider';

function SmartLink({
  href,
  alt,
  id,
  restricted = false,
  noPrefix = false,
  children,
  className,
  targetBlank = false,
}) {
  const { isAuth, setAuthModalOpen } = AuthUser();
  if (restricted && !isAuth) {
    return (
      <a
        className={className}
        style={{ cursor: 'pointer', textDecoration: 'none' }}
        onClick={() => setAuthModalOpen(true)}
      >
        {children}
      </a>
    );
  }

  if (!href || _isEmpty(href)) {
    return (
      <span id={id} className={className} alt={alt}>
        {children}
      </span>
    );
  }
  if (noPrefix
    || _startsWith(href, 'http:')
    || _startsWith(href, 'https:')
    || _startsWith(href, 'mailto:')
  ) {
    return (
      <a
        id={id}
        className={className}
        href={href}
        alt={alt}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  const { langId } = useParams();

  if (href === '/'
    || _startsWith('./', href)
    || _startsWith('../', href)
  ) {
    return (
      <Link
        className={className}
        alt={alt}
        to={href}
        id={id}
      >
        {children}
      </Link>
    );
  }

  const { rewrites } = window;
  const langRewrites = rewrites?.[langId] ?? {};
  const newHref = Object.entries(langRewrites).find((e) => e[1] === href)?.[0] ?? href;
  return (
    <Link
      id={id}
      className={className}
      to={`/${langId}/${newHref}`}
      alt={alt}
      target={targetBlank ? '_blank' : undefined}
    >
      {children}
    </Link>
  );
}

export default SmartLink;
