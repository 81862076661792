import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Link from './SmartLink';
import AuthUser from '../providers/auth.Provider';
import Login from './Login';
import Register from './Register';

function Authenticate({
  children, onSuccess, isModal, toggle, hasAccount: initialHasAccount = false,
}) {
  const [hasAccount, setHasAccount] = useState(initialHasAccount);
  const { isAuth } = AuthUser();
  const { t } = useTranslation();

  if (isAuth) {
    return children;
  }

  if (hasAccount) {
    return (
      <div className="comments mb-5 col-12">
        {!isModal && (
        <div className="title-lg card-title">
          <h3>
            {t('login')}
          </h3>
        </div>
        )}
        <Login onSuccess={onSuccess} />
        <div className="mb-4">
          <span>
            { t('non_hai_un_account') }
            {toggle
              ? (
                <Button
                  onClick={() => setHasAccount(false)}
                  variant="outline-primary"
                >
                  {t('subscribe')}
                </Button>
              )
              : (
                <Link href="subscribe">
                  {t('subscribe')}
                </Link>
              )}

          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="comments mb-5 col-12">
      {!isModal && (
      <div className="title-lg card-title">
        <h3>
          {t('subscribe')}
        </h3>
      </div>
      )}
      <div className="mb-4">
        <Register onSuccess={onSuccess} />
      </div>

      <span>
        {' '}
        {t('hai_un_account')}
        {' '}

        {toggle
          ? (
            <Button
              onClick={() => setHasAccount(true)}
              variant="outline-primary"
            >
              {t('login')}
            </Button>
          )
          : (
            <Link href="login">
              {t('login')}
            </Link>
          )}
      </span>
    </div>

  );
}

export default Authenticate;
