import { createContext, useContext } from 'react';
import useAuth from '../hooks/useAuth';

const authContext = createContext({});

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthUser() {
  return useContext(authContext);
}
