import _startsWith from 'lodash/startsWith';
import config from '../config';

const { API_URL } = config;

function getImgSrc(src) {
  const srcOrPH = src ?? '../img/placeholder-img.jpg';
  return (_startsWith(srcOrPH, 'http'))
    ? srcOrPH
    : `${API_URL}media/${srcOrPH}`;
}

export default getImgSrc;
