import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import Elements from './Elements';
import Section from '../layouts/Section';

function DynamicContent(props) {
  const { data, isSide = false } = props;
  const { t } = useTranslation();

  if (_isEmpty(data)) {
    return (
      <div className="text-center">
        <h1>{ t('loading')}</h1>
      </div>
    );
  }

  return (
    <>
      {_map(
        data,
        (sections) => (
          _map(
            sections,
            ({ main, side, classes = [] }, id) => (
              <Section
                key={id}
                classes={classes}
                side={side && <Elements data={side} isSide />}
                isSide={isSide}
              >
                <Elements data={main} />
              </Section>
            ),
          )),
      )}
    </>
  );
}

export default DynamicContent;
