import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../SectionHeader/Header';
import CardImage from '../Card/Image';

import css from './Events.module.css';
import PrettyDateRange from '../PrettyDateRange';

function Events({ events = [], coverImage }) {
  const [
    head, ...tail
  ] = events;
  const { t } = useTranslation();

  if (!head) {
    return null;
  }

  return (
    <div className="container-lg bg-secondary-light s2s-section-bg mb-5 pb-4">

      <SectionHeader
        className="section-title section-title-no-line mb-3"
        iconHref="events"
        title={{
          text: t('events'),
          href: 'events',
          className: 'title-xl text-capitalize heading-font',
        }}
      />

      <div className="row mb-3">
        <div className="col-md-8">

          <div className="card d-flex flex-wrap">
            <div className="mb-3">
              <CardImage
                href={head.url}
                src={coverImage}
                alt={head.title}
                isHorizontal
              />
            </div>
            <h3 className="title-xl card-title">
              <a href={head.url}>
                {head.title}
              </a>
            </h3>
            <div>
              <div className="mb-2 text-primary">
                {head.location && <div>{`${head.location}, `}</div>}
                {PrettyDateRange(head.date_from, head.date_to)}
              </div>
            </div>
          </div>

        </div>

        <div className="col-md-4">
          <div className={css.list}>
            {_map(
              tail,
              ({
                title,
                location,
                date_from: from,
                date_to: to,
                url,
              }, index) => (
                <div key={`${title}-${index}`}>
                  <div className="mb-2 text-primary">
                    {location && <div>{`${location}, `}</div>}
                    {PrettyDateRange(from, to)}
                  </div>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <h3 className="title-md mb-3">
                      {title}
                    </h3>
                  </a>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
