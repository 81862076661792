/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import _get from 'lodash/get';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import useAPI from '../hooks/useAPI';
import AuthUser from '../providers/auth.Provider';

function UserDataForm({ user, submitLabel, onSuccess = () => { } }) {
  const { t } = useTranslation();
  const [invoiceData, setInvoiceData] = useState(user?.invoice_use_data);
  const { langId } = useParams();
  const { check } = AuthUser();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const {
    onSubmit,
    isLoading,
    errorMsg,
  } = useAPI({
    onSuccess: () => {
      check();
      onSuccess();
    },
    url: `api/${langId}/subscription`,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col className="col-12 col-md-6">
          <Form.Group className={classNames({ 'mt-2': true, invalid: errors.name })}>
            <Form.Label>{t('name')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('name')} (${t('required')})`}
              {...register('name', {
                required: true,
                value: _get(user, ['name'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
        <Col className="col-12 col-md-6">
          <Form.Group className={classNames({ 'mt-2': true, invalid: errors.lastname })}>
            <Form.Label>{t('lastname')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('lastname')} (${t('required')})`}
              {...register('lastname', {
                required: true,
                value: _get(user, ['lastname'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mt-2">
            <Form.Label>{t('company')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('company')}`}
              {...register('company', {
                value: _get(user, ['company'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-2">
            <Form.Label>{t('phone')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('phone')}`}
              {...register('phone', {
                value: _get(user, ['phone'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mt-2">
            <Form.Label>{t('country')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('country')}`}
              {...register('country', {
                value: _get(user, ['country'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mt-2">
            <Form.Label>{t('city')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('city')}`}
              {...register('city', {
                value: _get(user, ['city'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mt-2">
            <Form.Label>{t('address')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('address')}`}
              {...register('address', {
                value: _get(user, ['address'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <Form.Group className="mt-2">
            <Form.Label>{t('cap')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('cap')}`}
              {...register('cap', {
                value: _get(user, ['cap'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
        <Col className="col-12 col-md-6">
          <Form.Group className="mt-2">
            <Form.Label>{t('province')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('province')}`}
              {...register('province', {
                value: _get(user, ['province'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h2>{t('account.invoice_data')}</h2>
        </Col>
      </Row>
      <Row className="">
        <Col>
          <input
            type="checkbox"
            id="_use_invoice_data"
            {...register('invoice_use_data', {
              value: _get(user, ['invoice_use_data'], 1),
            })
            }
            onChange={() => {
              setInvoiceData(!getValues('invoice_use_data'));
            }}
          />
          <label className="ms-2" htmlFor="_use_invoice_data">
            {t('account.use_invoice_data')}
          </label>
        </Col>
        <Col>
          <input
            type="checkbox"
            id="_invoice_no_vat"
            {...register('invoice_no_vat', {
              value: _get(user, ['invoice_no_vat'], 1),
            })
            }
          />
          <label className="ms-2" htmlFor="_invoice_no_vat">
            {t('account.no_vat')}
          </label>
        </Col>
      </Row>
      {!invoiceData ? (
        <>
          <Row>
            <Col className="col-12 col-md-6">
              <Form.Group className="mt-2">
                <Form.Label>{t('name')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('name')}`}
                  {...register('invoice_name', {
                    value: _get(user, ['invoice_name'], ''),
                  })
                  }
                />
              </Form.Group>
            </Col>
            <Col className="col-12 col-md-6">
              <Form.Group className="mt-2">
                <Form.Label>{t('lastname')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('lastname')}`}
                  {...register('invoice_lastname', {
                    value: _get(user, ['invoice_lastname'], ''),
                  })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mt-2">
                <Form.Label>{t('company')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('company')}`}
                  {...register('invoice_company', {
                    value: _get(user, ['invoice_company'], ''),
                  })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mt-2">
                <Form.Label>{t('country')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('country')}`}
                  {...register('invoice_country', {
                    value: _get(user, ['invoice_country'], ''),
                  })
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mt-2">
                <Form.Label>{t('city')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('city')}`}
                  {...register('invoice_city', {
                    value: _get(user, ['invoice_city'], ''),
                  })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mt-2">
                <Form.Label>{t('address')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('address')}`}
                  {...register('invoice_address', {
                    value: _get(user, ['invoice_address'], ''),
                  })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-md-6">
              <Form.Group className="mt-2">
                <Form.Label>{t('cap')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('cap')}`}
                  {...register('invoice_cap', {
                    value: _get(user, ['invoice_cap'], ''),
                  })
                  }
                />
              </Form.Group>
            </Col>
            <Col className="col-12 col-md-6">
              <Form.Group className="mt-2">
                <Form.Label>{t('province')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`${t('province')}`}
                  {...register('invoice_province', {
                    value: _get(user, ['invoice_province'], ''),
                  })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <Col>
          <Form.Group className="mt-2">
            <Form.Label>{t('email_address')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('email_address')}`}
              {...register('invoice_email', {
                value: _get(user, ['invoice_email'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <Form.Group className={classNames({ 'mt-2': true, invalid: errors.tax_code })}>
            <Form.Label>{t('tax_code')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('tax_code')} (${t('required')})`}
              {...register('tax_code', {
                required: true,
                value: _get(user, ['tax_code'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
        <Col className="col-12 col-md-6">
          <Form.Group className="mt-2">
            <Form.Label>{t('vat_number')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('vat_number')}`}
              {...register('vat_number', {
                value: _get(user, ['vat_number'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6">
          <Form.Group className="mt-2">
            <Form.Label>{t('sdi_code')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('sdi_code')}`}
              {...register('sdi_code', {
                value: _get(user, ['sdi_code'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
        <Col className="col-12 col-md-6">
          <Form.Group className="mt-2">
            <Form.Label>{t('pec')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={`${t('pec')}`}
              {...register('pec', {
                value: _get(user, ['pec'], ''),
              })
              }
            />
          </Form.Group>
        </Col>
      </Row>
      {errorMsg ? (
        <Row className="mt-3">
          <Col>
            <span>{t(errorMsg)}</span>
          </Col>
        </Row>
      ) : null}
      <Row className="mt-3">
        <Col>
          <Button
            type="submit"
            className="s2s-btn-accent text-uppercase btn-accent"
            style={{}}
          >
            {isLoading ? t('loading') : submitLabel}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default UserDataForm;
