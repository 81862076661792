import React from 'react';
import _isObject from 'lodash/isObject';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      message: '',
      stack: '',
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      message: error.message,
      stack: error.stack,
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    const { hasError, message, stack } = this.state;
    const { children, t } = this.props;
    const style = {
      main: {
        maxWidth: '75%',
        margin: '50px auto',
      },
      stack: {
        whiteSpace: 'pre-wrap',
      },
    };
    const msg = _isObject(message)
      ? JSON.stringify(message)
      : message;

    if (hasError) {
      return (
        <div style={style.main}>
          <h3>
            {t('fetch.error')}
          </h3>
          <div>{msg}</div>
          <pre className="panel-block" style={style.stack}>
            {stack}
          </pre>

        </div>
      );
    }

    return children;
  }
}
export default withTranslation()(ErrorBoundary);
