import _find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import Link from '../components/SmartLink';
import AuthUser from '../providers/auth.Provider';
import Configuration from '../providers/config.Provider';
import PrettyDate from '../components/PrettyDate';
import SubAccountsManagement from '../components/SubAccountsManagement';

function AccountPlan() {
  const { t } = useTranslation();
  const { user, check } = AuthUser();
  // const navigate = useNavigate();
  // const [email, setEmail] = useState(user?.email);
  // const { langId } = useParams();

  const {
    subscriptions,
  } = Configuration().config;

  const selectedPlan = user.subscription_plan_id
    ? _find(subscriptions, { id: parseInt(user.subscription_plan_id, 10) })
    : null;
  const activePlan = user.subscription_expires
    ? (new Date(user.subscription_expires)).getTime() >= (new Date()).getTime()
    : false;

  return (
    <>
      {selectedPlan
        ? (
          <>
            <div className={`choosen-plan ${activePlan ? 'ongoing' : 'expired'}`}>
              <span className={`your-choice ${activePlan ? 'ongoing' : 'expired'}`}>
                {activePlan ? t('account.plan_ongoing') : t('account.plan_expired')}
              </span>
              <div className="d-flex">
                <span className="material-symbols-rounded">
                  {activePlan ? 'check_circle' : 'close'}
                </span>

                <div className="choosen-plan-details">
                  <div className="duration">
                    {t(selectedPlan.duration)}
                  </div>
                  <div className="title-xl">
                    {`${selectedPlan.price} euro`}
                  </div>
                </div>
              </div>
              <div className="choosen-plan-features mx-3">
                {activePlan ? (
                  <>
                    <div className="feature">{t('account.expire_date')}</div>
                    <div className="feature"><PrettyDate date={new Date(user.subscription_expires)} /></div>
                  </>
                ) : (
                  <>
                    <div className="feature">{t('account.expired_date')}</div>
                    <div className="feature"><PrettyDate date={new Date(user.subscription_expires)} /></div>
                  </>
                )}
              </div>
            </div>
            {activePlan ? (
              <>
                <Link
                  className="btn btn-accent s2s-btn-accent mt-2"
                  href="subscribe"
                >
                  {t('account.change_plan')}
                </Link>
                <Link
                  className="btn s2s-btn-accent btn-outline-primary mt-2 ms-2"
                  href="subscribe"
                >
                  {t('account.suspend')}
                </Link>
              </>
            )
              : (
                <Link
                  className="btn btn-accent s2s-btn-accent mt-2"
                  href="subscribe"
                >
                  {t('account.change_plan_or_renews')}
                </Link>
              )}
          </>
        ) : (
          <>
            <div className="choosen-plan expired">
              <div className="d-flex">
                <span className="material-symbols-rounded">
                  close
                </span>

                <div className="choosen-plan-details">
                  <div className="duration">
                    {t('account.no_selected_plan')}
                  </div>
                </div>
              </div>
              <div className="choosen-plan-features mx-3">
                {activePlan ? (
                  <>
                    <div className="feature">{t('account.expire_date')}</div>
                    <div className="feature"><PrettyDate date={new Date(user.subscription_expires)} /></div>
                  </>
                ) : (
                  <>
                    <div className="feature">{t('account.expired_date')}</div>
                    <div className="feature"><PrettyDate date={new Date(user.subscription_expires)} /></div>
                  </>
                )}
              </div>
            </div>
            <Link
              className="btn btn-accent s2s-btn-accent mt-2"
              href="subscribe"
            >
              {t('subscribe')}
            </Link>
          </>
        )}

      {activePlan ? (
        <div className="account-panel mt-5">
          <div className="mb-5">
            <h2>{t('account.connected_users')}</h2>
            <SubAccountsManagement
              accounts={user?.accounts ? user.accounts : []}
              activePlan={activePlan}
              onSuccess={() => { check(); }}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AccountPlan;
