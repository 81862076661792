import _startsWith from 'lodash/startsWith';
import config from '../config';

const { API_URL } = config;

const getFileSrc = (src) => (
  _startsWith(src, 'http')
    ? src
    : `${API_URL}media/${src}`
);

export default getFileSrc;
