import _join from 'lodash/join';

function Section(props) {
  const {
    children,
    classes = [],
    isSide = false,
    side,
  } = props;

  let content = null;

  if (side) {
    content = (
      <>
        <div className="col-lg-9 main-area">
          {children}
        </div>
        <div className="col-lg-3 sidebar">
          <div className="col-dx">
            <div className="row">
              {side}
            </div>
          </div>
        </div>
      </>
    );
  } else if (isSide) {
    content = children;
  } else {
    content = (
      <div className="col-lg-12 main-area">
        {children}
      </div>
    );
  }

  return (
    <section className={`mb-4 ${_join(classes, ' ')}`}>
      <div className="row">
        {content}
      </div>
    </section>
  );
}

export default Section;
