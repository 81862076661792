import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import { useEffect, useState } from 'react';
import i18n from 'i18next';
// import { useParams } from 'react-router-dom';

export const DEFAULT_LANG = 'en';
const SUPPORTED_LANGS = ['it', 'en'];

export function isValidLang(language) {
  return !_isEmpty(language) && _includes(SUPPORTED_LANGS, language);
}

function useLanguage() {
  const [langId, _setLangId] = useState();

  const setLangId = (id) => {
    _setLangId(id);
    localStorage.setItem('lang', id);
    if (i18n.language !== id) {
      i18n.changeLanguage(
        id,
        () => {
          console.info(`switched to ${id}`);
        },
      );
    }
  };

  // on load get langId
  // local storage > param > browser > default
  useEffect(
    () => {
      // get lang from localstorage
      const storedLang = localStorage.getItem('lang');
      if (isValidLang(storedLang)) {
        setLangId(storedLang);
        return;
      }

      // get lang from url path
      // const { langId: paramsLang } = useParams();
      // if (isValidLang(paramsLang)) {
      //   setLangId(paramsLang);
      //   return;
      // }

      // get lang from broswer language
      const browserLang = navigator.language.split('-')[0];
      if (isValidLang(browserLang)) {
        setLangId(browserLang);
        return;
      }

      // return default value
      setLangId(DEFAULT_LANG);
    },
    [],
  );

  return [langId, setLangId];
}

export default useLanguage;
