import { useTranslation } from 'react-i18next';

import Link from '../SmartLink';
import FeatureList from './FeatureList';
import SubscriptionPlans from './Offers';
import BadgeS2S from '../BadgeS2S';
import Configuration from '../../providers/config.Provider';
import AuthUser from '../../providers/auth.Provider';

function Subscriptions({
  title,
  subtitle,
}) {
  const {
    subscriptions,
    features,
  } = Configuration().config;

  const {
    isAuth,
  } = AuthUser();

  const { t } = useTranslation();

  return (
    <div className="subscription-content pb-4">

      <BadgeS2S />

      <div className="title">
        {title}
      </div>

      <div className="subtitle">
        {subtitle}
      </div>

      <div className="features-wrapper my-5">
        <FeatureList features={features} />
      </div>

      <SubscriptionPlans subscriptions={subscriptions} />

      {isAuth ? null : (
        <div className="box-footer">
          <div className="already_subscribed">
            {t('already_subscribed')}
          </div>

          <Link restricted className="d-flex text-uppercase" href={false}>
            <strong className="me-1">{t('login')}</strong>
            <span className="material-icons-outlined">
              east
            </span>
          </Link>
        </div>
      )}

    </div>
  );
}
export default Subscriptions;
