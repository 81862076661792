import _map from 'lodash/map';

function FeatureList({ features }) {
  return (
    <div className="feature-list">
      <div className="row">
        {_map(features, (feature, index) => (
          <div key={`${feature}-${index}`} className="col-12 col-md-6 d-flex">
            <span className="material-symbols-rounded">
              check_circle
            </span>
            {feature}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeatureList;
