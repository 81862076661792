/* eslint-disable react/jsx-props-no-spreading */
import { t } from 'i18next';
import Form from 'react-bootstrap/Form';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import SectionHeader from './SectionHeader/Header';
import TransWithLinks from './TransWithLinks';
import useAPI from '../hooks/useAPI';

function Comment() {
  const [isSent, setIsSent] = useState(false);
  const { langId, id } = useParams();

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const {
    onSubmit,
    isLoading,
    errorMsg,
  } = useAPI({
    requireAuth: true,
    onSuccess: () => setIsSent(true),
    url: `api/${langId}/comments`,
  });

  if (isSent) {
    return (
      <div className="comments-widget mb-3">
        {t('thanks_for_posting')}
      </div>
    );
  }

  return (
    <div className="mb-5">
      <div className="comments-widget mt-3">

        <SectionHeader
          className="section-title-col-dx"
          title={{
            text: t('comment.leave_a'),
          }}
        />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <Form.Control
              rows={5}
              as="textarea"
              className="form-control"
              style={{ height: '150px' }}
              id="floatingTextarea2"
              placeholder={t('comment.title')}
              {...register('content', { required: true })}
            />
          </div>

          <div className="form-check mb-3">
            <Form.Check
              type="checkbox"
              label={(
                <TransWithLinks
                  i18nKey="accept_privacy_policy"
                  links={['page/privacy']}
                />
              )}
              id="flexCheckDefault"
              {...register('terms', {
                required: true,
              })}
            />
          </div>
          <input type="hidden" {...register('locale', { value: langId })} />
          <input type="hidden" {...register('article_id', { value: id })} />
          <input type="hidden" {...register('title', { value: '' })} />

          <input
            type="submit"
            className="btn btn-accent s2s-btn-accent text-uppercase"
            value={isLoading ? t('loading') : t('comment.button')}
          />
          {errorMsg && <span>{errorMsg}</span>}
        </Form>

      </div>

    </div>

  );
}

export default Comment;
