import PrettyDate from './PrettyDate';

const PrettyDateRange = (from, to) => {
  let startOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  const dateTo = new Date(to);
  const dateFrom = new Date(from);

  if (to) {
    if (
      dateTo.getMonth() === dateFrom.getMonth()
      && dateTo.getFullYear() === dateFrom.getFullYear()
    ) {
      if (dateTo.getDay() !== dateFrom.getDay()) {
        startOptions = {
          day: 'numeric',
        };
      }
    }
  }

  let res = PrettyDate({
    date: from,
    options: startOptions,
  });

  if (to && (
    dateTo.getDay() !== dateFrom.getDay()
    || dateTo.getMonth() !== dateFrom.getMonth()
    || dateTo.getFullYear() !== dateFrom.getFullYear()
  )) {
    const toDate = PrettyDate({
      date: to,
      options: {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      },
    });
    res += ` - ${toDate}`;
  }

  return res;
};

export default PrettyDateRange;
