/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AuthUser from '../providers/auth.Provider';
import fetchFn from '../utils/fetchFn';

const { Label, Group, Control } = Form;

function Login({ onSuccess = () => { } }) {
  const {
    login,
    error,
    loading,
    isAuth,
  } = AuthUser();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPaswordSuccess, setForgotPasswordSuccess] = useState(false);
  const { langId } = useParams();

  const { t } = useTranslation();
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (body) => {
    login(body, onSuccess);
  };

  const onRequestPasswordReset = (e) => {
    e.preventDefault();
    const { email } = getValues();
    fetchFn({
      url: `api/${langId}/forgot_password`,
      body: { email },
      method: 'POST',
    });
    setForgotPassword(false);
    setForgotPasswordSuccess(true);
  };

  if (forgotPaswordSuccess) {
    return (
      <Alert variant="success">
        {t('check_email_2')}
      </Alert>
    );
  }

  if (forgotPassword) {
    return (
      <Form onSubmit={onRequestPasswordReset}>
        <Label>
          {t('email_address')}
        </Label>

        <Group
          className="mb-3 text-form"
          controlId="forgotPasswordEmail"
        >

          <Control
            type="email"
            placeholder="Email"
            {...register('email', {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
          />
          {errors.email && <span>{t('valid_email')}</span>}
        </Group>
        <input
          type="submit"
          className="btn btn-accent s2s-btn-accent mb-3"
          value={loading ? t('loading') : t('submit')}
        />
      </Form>
    );
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      <Label>
        {t('email_address')}
      </Label>

      <Group
        className="mb-3 text-form"
        controlId="formBasicEmail"
      >

        <Control
          type="email"
          placeholder="Email"
          {...register('email', {
            required: true,
            pattern: /^\S+@\S+$/i,
          })}
        />
        {errors.email && <span>{t('valid_email')}</span>}
      </Group>

      <Label>
        {t('login_form.password')}
      </Label>

      <Group
        className="mb-3 text-form"
        controlId="formBasicPassword"
      >

        <Control
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          {...register('password', { required: true, maxLength: 40 })}
        />
        <button
          type="button"
          className="show-password"
          onClick={() => setShowPassword(!showPassword)}
        >
          <span className="material-icons-outlined">
            {showPassword ? 'visibility_off' : 'visibility'}
          </span>
        </button>
      </Group>

      <div className="mb-3">
        <Button className="link" onClick={() => setForgotPassword(true)}>
          {t('login_form.forgot_password')}
        </Button>

      </div>

      <div
        className="login-buttons mb-4"
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {error && <span className="p-2 text-center">{t(error)}</span>}
        <input
          type="submit"
          className="btn btn-accent s2s-btn-accent mb-3"
          value={loading ? t('loading') : t('login')}
        />

      </div>

    </Form>
  );
}

export default Login;
