import { useTranslation } from 'react-i18next';
import Link from './SmartLink';
import getFileSrc from '../utils/getFileSrc';
import PrettyDateRange from './PrettyDateRange';

function EventElement({ event }) {
  const {
    // id,
    date_from: from,
    date_to: to,
    title,
    location,
    attachment,
    // image = {
    //   url,
    //   url_big,
    //   url_small,
    //   description,
    // },
    // content,
    url,
  } = event;

  const { t } = useTranslation();

  const date = `${location ? `${location}, ` : ''}${PrettyDateRange(from, to)}`;
  return (
    <div className="event-element">
      <div>
        <span className="material-icons-outlined">
          calendar_today
        </span>
      </div>
      <div className="mx-4">
        <p>
          {date}
        </p>
        <a
          href={url}
          target="_blank"
          rel="nofollow noreferrer"
          className="d-flex flex-column"
        >
          <h3 className="title-md">
            {title}
          </h3>
        </a>
        {attachment
          ? (
            <Link
              href={getFileSrc(attachment)}
              className="mt-3 btn btn-outline-primary w-50"
            >
              {t('download')}
            </Link>
          )
          : null}
      </div>
    </div>
  );
}

export default EventElement;
