import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import _first from 'lodash/first';
import _find from 'lodash/find';

import getImgSrc from '../utils/getImgSrc';

import SearchBar from './SearchBar';
import SideMenu from './SideMenu';
import NavigationMenu from './NavigationMenu';
import Authenticate from './Authenticate';
import AuthUser from '../providers/auth.Provider';
import Configuration from '../providers/config.Provider';
// import getImgSrc from '../utils/getImgSrc';
import Image from './Image';
import Link from './SmartLink';
import LanguageSwitcher from './LanguageSwitcher';
import BannerPopup from './BannerPopup';
import BannerCarousel from './Banner/Carousel';
import HeaderEvents from './HeaderEvents';

function Header({
  aree,
  manchette = {},
  skin,
}) {
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState();
  const {
    isAuth,
    user,
    // logout,
    isAuthModalOpen,
    setAuthModalOpen,
  } = AuthUser();

  const {
    subscriptions,
    events,
  } = Configuration().config;

  const { langId } = useParams();

  const selectedPlan = isAuth && user.subscription_plan_id
    ? _find(subscriptions, { id: parseInt(user.subscription_plan_id, 10) })
    : null;
  const activePlan = isAuth && user.subscription_expires
    ? (new Date(user.subscription_expires)).getTime() >= (new Date()).getTime()
    : false;

  // @todo rimuovere perché in teoria sono tra i banner che devono ruotare
  // const manch = {
  //   left: manchette.left ? manchette.left[0] : null,
  //   right: manchette.right ? manchette.right[0] : null,
  // };

  const onScroll = () => {
    setIsScrolled(() => (window.pageYOffset > 44));
  };

  useEffect(() => {
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    // apply skin image to body element
    if (skin && skin.image_desktop) {
      _first(document.getElementsByTagName('body')).style.backgroundImage = `url(${getImgSrc(skin.image_desktop)})`;
    } else {
      _first(document.getElementsByTagName('body')).style.backgroundImage = ``;
    }
  }, [skin]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSearchOpen(false);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setIsMenuOpen(false);
  };

  const headerClassNames = (isScrolled)
    ? 'scrolled'
    : '';

  const onClose = () => {
    setAuthModalOpen(false);
  };

  const onOpen = () => ((isAuth)
    ? null
    : setAuthModalOpen(true));

  const containerClasses = classNames({ 'container-xxl': true, 'skin': skin });

  return (
    <>
      <BannerPopup />
      <section id="s2s-topMenu" className={headerClassNames}>
        <div className="container-xxl">
          <div className="row">
            <div className="col-sx col-6 col-md-3">
              <ul>
                <li>
                  <div
                    id="s2s-menuButton"
                    onClick={toggleMenu}
                    onKeyDown={toggleMenu}
                    tabIndex={0}
                    role="button"
                  >
                    <span className="material-icons-outlined">menu</span>
                    {' '}
                    <span className="etichetta-menu d-none d-md-block">
                      {t('menu')}
                    </span>
                  </div>
                </li>
                <li className="d-none d-md-block">
                  <LanguageSwitcher />
                </li>
                <li>
                  <div
                    id="s2s-searchButton"
                    onClick={toggleSearch}
                    onKeyDown={toggleSearch}
                    tabIndex={0}
                    role="button"
                  >
                    <span className="material-icons-outlined">search</span>
                    {' '}
                    <span className="etichetta-menu d-none d-md-block">
                      {t('search.text')}
                    </span>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col d-none d-md-flex col-md-6 d-flex align-items-center justify-content-center">

              {selectedPlan && activePlan
                ? null
                : (
                  <Link href="subscribe" className="link-abbonati">
                    {t('subscribe')}
                  </Link>
                )}

              <Link href="/" id="logo-topMenu">
                {/* <!-- link a home --> */}
                <Image url={`../img/logo-topMenu-${langId}.png`} alt="Ship2Shore logo" />
              </Link>
            </div>

            <div className="col-dx col-6 col-md-3">
              <Button
                className="s2s-btn-accent link-accedi-dx pe-0"
                onClick={onOpen}
              >
                {isAuth
                  ? (
                    <>
                      {t('header.welcome')}
                      {/* <span className="material-icons-outlined">
                        logout
                      </span>
                      {t('header.logout')} */}
                    </>
                  )
                  : (
                    <>
                      <span className="material-icons-outlined">
                        login
                      </span>
                      {t('header.login')}
                    </>
                  )}
              </Button>
              {selectedPlan && activePlan
                ? null
                : (
                  <Link className="btn btn-accent s2s-btn-accent link-abbonati-dx ms-4" href="subscribe">
                    {t('subscribe')}
                  </Link>
                )}
              <Modal
                show={isAuthModalOpen}
                onHide={onClose}
                backdrop="static"
                keyboard
              >
                <Modal.Header closeButton>
                  <div className="title-lg">
                    <h3>
                      {t('auth')}
                    </h3>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <Authenticate isModal onSuccess={onClose} hasAccount />
                </Modal.Body>
              </Modal>

            </div>

          </div>

        </div>
      </section>
      {/* <!--// top menu --> */}
      <header id="s2s-header">

        <HeaderEvents events={events}></HeaderEvents>

        <SearchBar isSearchOpen={isSearchOpen} toggleSearch={toggleSearch} />

        <section className={containerClasses} id="s2s-logoSection">
          <div className="container-xxl">
            <div className="row">
              <div className="col-2 col-lg-3">
                <div className="d-none d-lg-block col-sx d-flex align-items-center justify-content-center">
                  <div className="s2s-banner">
                    <BannerCarousel content={manchette.left ? manchette.left : []} timer="5000" />
                  </div>
                </div>
              </div>

              <div className="col-8 col-lg-6 d-flex flex-column align-items-center justify-content-center">
                <Link href="/" id="logo">
                  {/* <!-- link a home --> */}
                  <Image url={`../img/logo-${langId}.png`} alt="Ship2Shore logo" />
                  <span className="visually-hidden-focusable">
                    {t('header.info')}
                  </span>
                </Link>
                <p className="mt-2 text-sm">{t('header.editor')}</p>

              </div>

              <div className="col-2 col-lg-3">
                <div className="d-none d-lg-block col-dx d-flex align-items-center justify-content-center">
                  <div className="s2s-banner">
                    <BannerCarousel content={manchette.right ? manchette.right : []} timer="5000" />
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>
        {/* <!--// logo Section --> */}

        <NavigationMenu aree={aree} />

        <SideMenu
          aree={aree}
          langId={langId}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
        />
      </header>
    </>
  );
}

export default Header;
