import { t } from 'i18next';
import { Link, useParams } from 'react-router-dom';

function Message({ message, showBackToHome = false, children }) {
  const { langId } = useParams();
  return (
    <div className="text-center">
      <h2 className="my-5">
        {t(message)}
      </h2>
      {children}
      {showBackToHome
        ? (
          <Link to={`/${langId}`}>
            {t('back_to_home')}
          </Link>
        )
        : null}
    </div>
  );
}

export default Message;
