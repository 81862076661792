import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DynamicPagedContent from '../components/DynamicPagedContent';
import EventListElement from '../components/EventListElement';
import YearMonthSelector from '../components/YearMonthSelector';

function Events() {
  const { t } = useTranslation();
  const {
    langId,
  } = useParams();
  const [m, setM] = useState('');
  const [y, setY] = useState('');

  // sets current year/month as default value
  // useEffect(
  //   () => {
  //     const currentYY = new Date().getFullYear();
  //     const currentMM = new Date().getMonth();
  //     setY(currentYY);
  //     setM(currentMM);
  //   },
  //   [],
  // );

  return (
    <>
      <YearMonthSelector
        name={t('events')}
        year={y}
        month={m}
        setYear={setY}
        setMonth={setM}
        getYearsUrl={`api/${langId}/events/years`}
        getMonthsUrl={(mm) => `api/${langId}/events/months/${mm}`}
      />
      <DynamicPagedContent
        langId={langId}
        method="GET"
        apiUrl={`api/${langId}/events`}
        apiUrlParams={`year=${y}&month=${m}`}
        PageComponent={EventListElement}
        dataField="data"
      />
    </>
  );
}

export default Events;
