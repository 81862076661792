import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import _map from 'lodash/map';

import Link from './SmartLink';
import Image from './Image';
import { CONTENTS } from '../constants/sidemenu';
import fetchFn from '../utils/fetchFn';
import Socials from './Socials';

function Footer({
  aree,
}) {
  const { t } = useTranslation();
  // const { langId } = useParams();

  // const [hideCookies, setHideCookies] = useState(localStorage.getItem('__cookies') !== null);

  // const acceptCookies = (consent) => {
  //   fetchFn({
  //     url: `api/${langId}/config/cookie-consent`,
  //     method: 'PUT',
  //     body: { consent },
  //   }).then(() => {
  //     localStorage.setItem('__cookies', consent);
  //     setHideCookies(true);
  //     window.location.reload();
  //   });
  // };

  return (
    <>
      <div id="s2s-footer" className="mt-4">
        <div className="container-xxl py-3">
          <div className="row py-3">
            <div className="s2s-first-col col-sm-4 flex-column">
              <Link href="/" id="logo-topMenu">
                <picture className="mx-auto">
                  <source srcSet="/img/logo-topMenu.webp" type="image/webp" />
                  <source srcSet="/img/logo-topMenu.png" type="image/jpeg" />
                  <Image url="../img/logo-topMenu.png" className="w-100" alt="Ship2Shore logo" />
                </picture>
              </Link>
              <p className="mt-2 mb-0">©Ship2Shore - VAT nr. 01477140998</p>
              <p>All rights reserved | ISSN: 2499-8761</p>
              <ul>
                <li><Link href="page/about">{t('page/about')}</Link></li>
                <li><Link href="page/contacts">{t('page/contacts')}</Link></li>
              </ul>
            </div>
            <div className="s2s-second-col col-sm">
              <h4>{t('areas')}</h4>
              <ul>
                {_map(aree, ({ id, name }) => (
                  <li key={id}>
                    <Link href={`category/${id}`}>{name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="s2s-third-col col-sm">
              <h4>{t('contents')}</h4>
              <ul>
                {CONTENTS.map(
                  (key) => (
                    <li key={key}>
                      <Link href={key}>
                        {t(key)}
                      </Link>
                    </li>
                  ),
                )}
              </ul>
            </div>
            <div className="s2s-fourth-col col-sm">
              <h4>{t('utilities')}</h4>
              <ul>
                <li><Link href="page/privacy">Privacy Policy</Link></li>
                <li><Link href="page/ads">{t('page/ads')}</Link></li>
              </ul>
              <div className="s2s-social-container s2s-social-container-footer mb-3">
                <Socials />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* !hideCookies ? (
        <div className="s2s-cookie-banner content w-100 p-3 border border-2 border-primary position-fixed bottom-0 bg-white">
          <div className="row justify-content-around align-middle">
            <div className="col-md-8 p-2">
              <p className="mb-0">
                {t('cookies.text')}
              </p>
            </div>
            <div className="col-md-4 p-2 text-end">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => acceptCookies(true)}
              >
                {t('cookies.accept_button')}
              </button>
              <Link className="ms-4 link-cookies-details" href="cookies">
                {t('cookies.details_button')}
              </Link>
            </div>
          </div>
        </div>
      ) : null */}
    </>
  );
}

export default Footer;
