import _isFunction from 'lodash/isFunction';
import _capitalize from 'lodash/capitalize';
import { useParams } from 'react-router-dom';
import DynamicPagedContent from '../components/DynamicPagedContent';
import Configuration from '../providers/config.Provider';

function Category(props) {
  const params = useParams();
  const { langId } = params;
  let { categoryId, subcategoryId } = props;
  if (categoryId === undefined) {
    categoryId = params.categoryId;
  }
  if (subcategoryId === undefined) {
    subcategoryId = params.subcategoryId;
  }
  const { getSubname } = Configuration().config;
  const subcategory = _isFunction(getSubname)
    ? getSubname(subcategoryId)
    : subcategoryId;

  return (
    <>
      <section className="mb-4">
        <div className="row">
          <div className="col">
            <h1 className={`text-${categoryId}`}>
              {`${_capitalize(subcategory)}`}
            </h1>
          </div>
        </div>
      </section>
      <DynamicPagedContent
        injectUrl={`articles/category/${subcategoryId}`}
        langId={langId}
      />
    </>
  );
}

export default Category;
