import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Configuration from '../providers/config.Provider';

function Layout() {
  const {
    config,
    manchette,
  } = Configuration();

  return (
    <>
      <Header aree={config.areas} manchette={manchette} skin={config.skin} />
      <div className="page container-xxl page-wrapper">
        <main>
          <Outlet />
        </main>
      </div>
      <Footer aree={config.areas} />
    </>
  );
}

export default Layout;
