import _map from 'lodash/map';
import Link from './SmartLink';

function TagLinks({ links = [] }) {
  return (
    <div className="articleTags mb-5">
      <ul className="list-group list-group-horizontal">
        {_map(links, ({ label }) => (
          <li key={label}>
            <Link
              href={`archive?term=#${label}`}
              className="btn btn-sm btn-outline-primary text-uppercase"
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default TagLinks;
