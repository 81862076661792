import _isFunction from 'lodash/isFunction';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../components/ProgressBar';
import SubscriptionHeader from '../components/Subscriptions/Header';
import UserDataForm from '../components/UserDataForm';
import AuthUser from '../providers/auth.Provider';

function Subscribe2() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { user } = AuthUser();

  const id = parseInt(searchParams.get('plan'), 10);

  const onSuccess = (ev) => {
    if (_isFunction(ev?.preventDefault)) {
      ev.preventDefault();
    }
    navigate(`../subscribe/3?plan=${id}`);
  };

  return (
    <div
      className="subscription-page"
      style={{
        width: '50%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SubscriptionHeader />

      <ProgressBar availableLevels={[1, 2, 3, 4]} currentLevel={2} />

      <div className="info">
        <div className="comments mb-5">
          <div className="title-lg card-title mb-3">
            <h3>
              {t('your_data')}
            </h3>
          </div>

          <UserDataForm
            user={user}
            submitLabel={t('next')}
            onSuccess={onSuccess}
          />
        </div>
      </div>
    </div>
  );
}

export default Subscribe2;
