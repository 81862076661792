import { t } from 'i18next';

function PageTitle(props) {
  const { text } = props;

  if (!text) {
    return null;
  }

  return (
    <section className="mb-4">
      <div className="row">
        <div className="col">
          <h1>
            {t(text)}
          </h1>
        </div>
      </div>
    </section>
  );
}

export default PageTitle;
