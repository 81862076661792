import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _isFunction from 'lodash/isFunction';
import { useTranslation } from 'react-i18next';
import Link from './SmartLink';
import getImgSrc from '../utils/getImgSrc';
import HtmlEmbed from './HtmlEmbed';
import PrettyDate from './PrettyDate';
import Subscriptions from './Subscriptions';
import Configuration from '../providers/config.Provider';
import RestrictedBadge from './RestrictedBadge';

function ArticleContent(props) {
  const {
    title,
    meta_title,
    content,
    date,
    image,
    abstract,
    meta_description,
    areas,
    forbidden,
    subscription,
    highlight = false,
    advertorial = false,
  } = props;
  const { t } = useTranslation();
  const { getName } = Configuration().config;
  const getArea = _isFunction(getName)
    ? getName
    : () => '?';

  const metaTitle = meta_title ? meta_title : title;
  const metaDescription = meta_description ? meta_description : abstract;

  document.title = `${metaTitle} | Ship2Shore`;
  document.querySelector('meta[property="og:title"]').setAttribute('content', `${metaTitle} | Ship2Shore`);
  document.querySelector('meta[property="og:description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
  document.querySelector('meta[property="og:type"]').setAttribute('content', 'article');
  if (!_isEmpty(image)) {
    document.querySelector('meta[property="og:image"]').setAttribute('content', getImgSrc(image?.url));
    document.querySelector('meta[property="og:image:secure_url"]').setAttribute('content', getImgSrc(image?.url));
    document.querySelector('meta[property="og:image:alt"]').setAttribute('content', image?.description ?? title);
  }

  return (
    <>
      <div className="articleHeader mb-4">
        <div className="mb-3">
          <div className="mb-4">
            <div className="sts-badge-container-article mb-3">
              <RestrictedBadge subscription={subscription} />
              {highlight ? <span className="sts-subhead">{highlight}</span> : null}
              {advertorial ? <span className="sts-subhead sts-advertorial">{t('advertorial')}</span> : null}
            </div>
            {
              _map(areas, ({ area_id: areaId, subcat }) => {
                const areaName = getArea(areaId);
                return (
                  <div className={`s2s-categories-group category-${areaId}`} key={areaId}>
                    <Link href={`category/${areaId}`} className="s2s-category">
                      {areaName}
                    </Link>
                    {_map(subcat, ({ name, id: subcategoryId }) => (name !== areaName ? (
                      <Link
                        key={subcategoryId}
                        className="s2s-subcategory mx-2"
                        href={`category/${areaId}/${subcategoryId}`}
                      >
                        {name}
                      </Link>
                    ) : null
                    ))}
                  </div>
                );
              })
            }
          </div>

        </div>
        <h1 className="mb-3">
          {title}
        </h1>
        <h2 className="mb-3">
          {abstract}
        </h2>
        {date ? (
          <div className="text-sm text-uppercase text-primary">
            <PrettyDate date={date} />
          </div>
        ) : null}
      </div>
      {!_isEmpty(image)
        ? (
          <div className="articlePhoto">
            <img
              src={getImgSrc(image?.url)}
              alt={image?.description}
              className="img-fluid"
            />
            {image?.description ? (
              <>
                <br />
                <i><sub>{image?.description}</sub></i>
              </>
            ) : ''}
          </div>
        )
        : null}
      <div className="articleContent mb-5">
        <HtmlEmbed content={content} />
        <div className="clearfix" />
        {(subscription && forbidden) ? (
          <div className="subscribe forbidden">
            <Subscriptions
              title={t('restricted_content')}
              subtitle={t('choose_your_plan')}
            />
          </div>
        ) : ''}
      </div>
    </>
  );
}

export default ArticleContent;
