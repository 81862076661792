import { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import _isFunction from 'lodash/isFunction';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../components/ProgressBar';
import SubscriptionHeader from '../components/Subscriptions/Header';
import fetchFn from '../utils/fetchFn';
import AuthUser from '../providers/auth.Provider';

const availableOptions = {
  CARD: 'carta',
  BANK_TRANSFER: 'bonifico',
};

function Subscribe3() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { langId } = useParams();
  const { t } = useTranslation();
  const { check } = AuthUser();
  const {
    CARD,
    BANK_TRANSFER,
  } = availableOptions;

  const [embedVisible, setEmbedVisible] = useState(availableOptions.CARD);

  const id = parseInt(searchParams.get('plan'), 10);

  const proceedBank = () => {
    fetchFn({
      url: `api/${langId}/payment/page/${id}`,
      method: 'GET',
    }).then((data) => {
      if (data.payment_page && data.key) {
        window.location = data.payment_page;
      }
    });
  };

  const proceed = () => {
    fetchFn({
      url: `api/${langId}/user/subscribe_to`,
      method: 'POST',
      body: { plan: id },
    }).then(() => {
      check();
      navigate('../subscribe/4');
    });
  };

  return (
    <div
      className="subscription-page"
      style={{
        width: '50%',
        margin: '0 auto',
      }}
    >

      <SubscriptionHeader />

      <ProgressBar availableLevels={[1, 2, 3, 4]} currentLevel={3} />

      <div
        className="metodo-pagamento row"
      >
        <div className="col-12 col-sm-4 col-md-3">
          <Form>
            <div key="reverse-radio" className="mb-5 pe-5">
              <Form.Check
                reverse
                label={t('card')}
                name="group1"
                onClick={() => setEmbedVisible(CARD)}
                type="radio"
                id="reverse-radio-1"
                defaultChecked
              />
              <Form.Check
                reverse
                label={t('bank_transfer')}
                onClick={() => setEmbedVisible(BANK_TRANSFER)}
                name="group1"
                type="radio"
                id="reverse-radio-2"
              />
            </div>
          </Form>
        </div>
        {embedVisible === BANK_TRANSFER ? (
          <div className="col-12 col-sm-8 col-md-9">
            <div className="bonifico">
              <p>
                <strong>{t('bank_transfer')}</strong>
              </p>
              <p>
                ESA Srl
              </p>
              <p>
                {t('company_address')}
                {' '}
                Via Assarotti 38/16 - 16122 Genova
                <br />

                <strong>IBAN: IT10 N 03268 01400 052869513730</strong>
                {' '}
                <br />
                c/o Banca Sella, filiale di Genova
              </p>
              <p>
                {t('bank_transfer_notify')}
                {' '}
                <a href="mailto:amministrazione@ship2shore.it">amministrazione@ship2shore.it</a>
              </p>
            </div>
            <div className="info">
              <Button
                onClick={proceed}
                className="btn btn-accent s2s-btn-accent text-uppercase"
              >
                {t('next')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="col-12 col-sm-8 col-md-9">
            <div className="carta">
              <p>
                <strong>{t('card')}</strong>
              </p>
              <p>
                {t('card_proceed_to_payment')}
              </p>
            </div>
            <div className="info">
              <Button
                onClick={proceedBank}
                className="btn btn-accent s2s-btn-accent text-uppercase"
              >
                {t('next')}
              </Button>
            </div>
          </div>
        )
        }
      </div>

    </div>

  );
}

export default Subscribe3;
