import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import Link from './SmartLink';
import LanguageSwitcher from './LanguageSwitcher';
import AuthUser from '../providers/auth.Provider';
import Socials from './Socials';
import { CONTENTS, S2S, ACTIONS } from '../constants/sidemenu';

function SideMenu(props) {
  const { t } = useTranslation();
  const { isAuth, logout } = AuthUser();
  const {
    aree,
    isMenuOpen,
    toggleMenu,
  } = props;

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div id="s2s-sideMenu" className={`${isMenuOpen ? 'menu-open' : ''}`} onClick={toggleMenu}>

      <div className="s2s-social-container mb-3">
        <Socials />
        <div className="d-sm-block d-md-none">
          <LanguageSwitcher />
        </div>
      </div>

      <div
        id="s2s-closeMenu"
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
        tabIndex={0}
        role="button"
      >
        <span className="material-icons-outlined">close</span>
      </div>

      <div className="heading-with-line">
        <h4 className="heading-font title-lg">
          {t('areas')}
        </h4>
      </div>

      <ul className="mn-list">
        {_map(aree, ({ id, name }) => (
          <li key={id}>
            <Link href={`category/${id}`}>{name}</Link>
          </li>
        ))}
      </ul>

      <div className="heading-with-line">
        <h4 className="heading-font title-lg">
          {t('contents')}
        </h4>
      </div>

      <ul className="mn-list">
        {CONTENTS.map(
          (key) => (
            <li key={key}>
              <Link href={key}>
                {t(key)}
              </Link>
            </li>
          ),
        )}
      </ul>

      <div className="heading-with-line">
        <h4 className="heading-font title-lg">
          Ship2Shore
        </h4>
      </div>

      <ul className="mn-list">
        {S2S.map(
          (key) => (
            <li key={key}>
              <Link href={key}>
                {t(key)}
              </Link>
            </li>
          ),
        )}
      </ul>

      {(isAuth)
        ? (
          <>
            <div className="heading-with-line">
              <h4 className="heading-font title-lg">
                {t('user_menu')}
              </h4>
            </div>

            <ul className="mn-list">
              <li>
                <Link href="profile">
                  {t('profile')}
                </Link>
              </li>
              <li>
                <Button className="logout-link" onClick={logout}>{t('logout')}</Button>
              </li>
            </ul>
          </>
        )
        : null}

      <ul className="btn-list">
        {ACTIONS.map(
          (key) => (
            <li key={key}>
              <Link
                className="btn btn-accent s2s-btn-accent"
                href={key}
              >
                {t(key)}
              </Link>
            </li>
          ),
        )}
        {(isAuth)
          ? null
          : (
            <li>
              <Link restricted className="btn btn-accent s2s-btn-accent" href={false}>
                {t('login')}
              </Link>
            </li>
          )}
      </ul>

    </div>
  );
}

export default SideMenu;
