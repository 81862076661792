import {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import fetchFn from '../utils/fetchFn';
import useLanguage from '../hooks/useLanguage';
import getAreaName from '../utils/getAreaName';
import getCategoryName from '../utils/getCategoryName';

const confContext = createContext({
  manchette: {},
  config: {},
  homepageHeight: 0,
  translatedUrl: '',
  updateFromTemplate: () => { },
  updateHomepageHeight: () => { },
});

export function ConfProvider({ children }) {
  const [langId, setLangId] = useLanguage();

  const initialManchette = {
    left: [],
    right: [],
  };
  const initialState = {
    langId,
    setLangId,
    getName: (id) => id,
    getSubname: (id) => id,
    areas: [],
    events: [],
    manchette: {},
    skin: null,
  };

  const [manchette, setManchette] = useState(initialManchette);
  const [config, setConfig] = useState(initialState);
  const [translatedUrl, setTranslatedUrl] = useState('');
  const [homepageHeight, setHomepageHeight] = useState(0);
  // const [loadedOnce, setLoadedOnce] = useState(false);

  const updateHomepageHeight = (h) => {
    setHomepageHeight(h);
  };

  const updateFromTemplate = (res) => {
    // if (!loadedOnce) {
    //   return;
    // }

    let layout = null;
    let left = null;
    let right = null;
    let url = '';

    if (res && res.layout && res.layout[0] && res.layout[0][0] && res.layout[0][0].main) {
      layout = res.layout[0][0].main;
    } else if (res && res.layout && res.layout.top) {
      layout = res.layout.top;
    }

    if (layout) {
      layout.forEach((e) => {
        if (e.type === 'manchette-left') {
          left = e.content;
        } else if (e.type === 'manchette-right') {
          right = e.content;
        } else if (e.type === 'translated-url') {
          url = e.content;
        }
      });
    }

    if (left !== null || right !== null) {
      setManchette({
        left: left || [],
        right: right || [],
      });
    }

    setTranslatedUrl(url);
  };

  // on langId change, fetch new config
  useEffect(
    () => {
      if (!langId) {
        return;
      }
      fetchFn({
        url: `api/${langId}/config`,
      }).then(
        (res) => {
          setConfig({
            langId,
            setLangId,
            areas: res?.areas,
            // manchette: res?.manchette,
            skin: res?.skin,
            popup: res?.popup,
            events: res?.events,
            getName: getAreaName(res?.areas),
            getSubname: getCategoryName(res?.categories),
            features: res.subscription.features,
            subscriptions: res.subscription.subscriptions,
          });
          // setLoadedOnce(true);
        },
      );
    },
    [langId],
  );

  const memo = useMemo(() => ({
    manchette,
    homepageHeight,
    translatedUrl,
    config,
    // updateManchette,
    updateFromTemplate,
    updateHomepageHeight,
  }), [manchette, config]);

  return <confContext.Provider value={memo}>{children}</confContext.Provider>;
}

export default function Configuration() {
  return useContext(confContext);
}
