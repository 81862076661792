import _map from 'lodash/map';
import createSlug from '../../utils/createSlug';
import VideoCard from './Card';

function VideoCardList({ videos }) {
  if (!videos) {
    return null;
  }

  return (
    <div className="row mb-3">
      {_map(videos, ({
        image, title, id, created_at: createdAt, published_at: publishedAt, slug
      }) => (
        <div className="col-md-4" key={id}>
          <VideoCard
            size="sm"
            image={image?.url}
            desc={image?.description}
            title={title}
            href={`video/${slug}`}
            date={publishedAt ?? createdAt}
          />
        </div>
      ))}
    </div>
  );
}

export default VideoCardList;
