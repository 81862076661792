import _map from 'lodash/map';
import PodcastItem from './Item';

function PodcastList({ items }) {
  if (!items) {
    return null;
  }
  return (
    <div className="podcast-list mb-3 pb-5">
      {_map(items, (podcast, id) => (
        <PodcastItem podcast={podcast} key={id} />
      ))}
    </div>
  );
}

export default PodcastList;
