import { useTranslation } from 'react-i18next';
import Link from '../SmartLink';

function SectionIcon({ href }) {
  const { t } = useTranslation();

  return (
    <Link href={href} className="section-title-link">
      <span className="d-none d-md-block">
        {t('see_all')}
      </span>
      <span className="material-icons-outlined">
        east
      </span>
    </Link>
  );
}

export default SectionIcon;
