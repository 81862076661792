import { Navigate } from 'react-router-dom';
import Message from '../components/Message';
import { DEFAULT_LANG, isValidLang } from '../hooks/useLanguage';

function RedirectToHome() {
  // NOTA
  // previous version:
  // const { langId } = Configuration();
  //
  // non possiamo aspettare i comodi degli hook e del provider perche'
  // settano langId tramite state (quindi lazy-set)
  // mentre a questa componente serve il risultato subito

  const storedLang = localStorage.getItem('lang');
  const browserLang = navigator.language.split('-')[0];
  // eslint-disable-next-line no-nested-ternary
  const langId = isValidLang(storedLang)
    ? storedLang
    : isValidLang(browserLang)
      ? browserLang
      : DEFAULT_LANG;

  return (
    <>
      <Message message="loading" />
      <Navigate replace to={`/${langId}`} />
    </>
  );
}

export default RedirectToHome;
