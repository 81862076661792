import BannerPopup from '../components/BannerPopup';
import SpecialItem from '../components/SpecialList/Item';
import Widget from '../components/Widget';

function Demo() {
  return (
    <>
      <section>

        <BannerPopup
          image={{
            src: '/test-img/test-img.jpeg',
            alt: 'image alt',
          }}
          title="Offerta a tempo"
          id={12}
        />
        <SpecialItem
          item={{
            id: 12,
            title: 'titolo special item titolo special item titolo special item',
            subtitle: 'sottotitolo elemento speciale numero 12',
            attachment: 'attachmentlink',
          }}
          image={{
            url: '',
            description: 'Et ut quis non rerum culpa. Voluptatem natus porro dolorum quidem natus voluptatem. Optio distinctio deleniti assumenda voluptatem aut numquam. Et autem corporis molestiae et est aut quia. (IT)',
          }}
        />

        <Widget
          category="in evidenza"
          className="topics"
          articles={[{
            title: 'Premuda prende in consegna la PS New Orleans',
            href: '/',
          },
          {
            title: 'xx',
            href: '/',
          },
          {
            title: 'bbb',
            href: '/',
          },

          ]}
        />

        <div className="py-3 text-danger"><strong>Headings</strong></div>

        <h1>Titolo h1</h1>
        <h2>Titolo h2</h2>
        <h3>Titolo h3</h3>
        <h4>Titolo h4</h4>
        <h5>Titolo h5</h5>
        <h6>Titolo h6</h6>

        <div className="py-3 text-danger"><strong>Classi per headings</strong></div>

        <h3 className="title-xl">Titolo xl</h3>
        <h3 className="title-lg">Titolo lg</h3>
        <h3 className="title-md">Titolo md</h3>
        <h3 className="title-sm">Titolo sm</h3>

        <div className="py-3 text-danger"><strong>Stile testo</strong></div>

        <p>
          Lorem ipsum dolor sit amet,
          <strong>consectetur adipiscing elit</strong>
          .
          Ut a tincidunt leo. Quisque lacinia elementum
          <i>lorem eget posuere</i>
          .
          Praesent gravida nisl in libero feugiat vulputate.
          Nam semper finibus lacinia. Morbi fringilla magna
          sed urna rhoncus ultrices vitae eget odio.
          Sed rhoncus tempor nulla, ac fringilla eros.
          Sed tincidunt massa erat. Suspendisse magna nibh,
          blandit aliquet nisl et, facilisis mattis ante.
        </p>

        <p>
          Fusce a laoreet risus, et cursus ipsum. Nullam faucibus non diam eget luctus.
          <a href="/">Fusce elementum risus tellus</a>
          in ultricies urna viverra at. Cras in commodo dolor, quis pulvinar mi.
          Mauris consequat sagittis leo in cursus.
          Pellentesque at lorem nec dolor feugiat convallis.
          Praesent varius in purus ac tempor.
          Nunc convallis tempor nisl, sed scelerisque arcu eleifend at.
          Praesent dignissim aliquam odio vitae lacinia.
        </p>
        <ul>
          <li>Fusce a laoreet risus</li>
          <li>Et cursus ipsum</li>
          <li>Nullam faucibus non diam eget luctus</li>
        </ul>

        <ol>
          <li>Fusce a laoreet risus</li>
          <li>Et cursus ipsum</li>
          <li>Nullam faucibus non diam eget luctus</li>
        </ol>
      </section>

      <section>
        <div className="py-3 text-danger">
          <strong>Badge subscription</strong>
        </div>

        Caso 1
        da usare nelle card

        <span className="badge bg-secondary  s2s-badge-sm">S2S+</span>

        Caso 2 articolo singolo
        <span className="badge bg-secondary text-sm s2s-badge-lg">
          <span>Contenuto per gli Abbonati</span>
          S2S+
        </span>

        <div className="py-3 text-danger">
          <strong>Badge</strong>
        </div>

        <span className="badge bg-primary">3 NEW</span>

      </section>

      <section>
        <div className="py-3 text-danger"><strong>Buttons</strong></div>
        <a
          className="btn btn-primary text-uppercase"
          href="/"
          role="button"
        >
          Link
        </a>
        <button
          type="button"
          className="btn btn-outline-primary text-uppercase"
        >
          Primary
        </button>
        <a className="btn btn-accent s2s-btn-accent text-uppercase" href="/" role="button">Link</a>
      </section>

      <section>
        <div className="py-3 text-danger"><strong>Titolo sezioni</strong></div>

        <div className="section-title mb-3">
          <h2><a href="/">Titolo sezione CON link</a></h2>
          <a href="/" className="section-title-link">
            <span className="d-none d-md-block">Guarda tutti</span>
            {' '}
            <span className="material-icons-outlined">east</span>
          </a>
        </div>

        <div className="section-title mb-3">
          <h2>Titolo sezione SENZA link </h2>
        </div>

        <div className="section-title section-title-no-line mb-3">
          <h2 className="text-capitalize heading-font">
            <a href="/">Titolo sezione senza LINEA</a>
          </h2>
          <a href="/" className="section-title-link">
            <span className="d-none d-md-block">Guarda tutti</span>
            <span className="material-icons-outlined"> east</span>
          </a>
        </div>

        <div className="section-title section-title-col-dx mb-3">
          <h2>Titolo sezione colonna destra</h2>
        </div>
      </section>
    </>
  );
}

export default Demo;
