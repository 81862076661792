import Icon from './Icon';
import Title from './Title';

function SectionHeader(props) {
  const { className, title = {}, iconHref } = props;

  const titleClassName = `section-title mb-3 ${className}`;

  return (
    <div className={titleClassName}>
      <Title title={title} />
      {iconHref && (
        <Icon href={iconHref} />
      )}
    </div>
  );
}

export default SectionHeader;
