import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Link from './SmartLink';

function NavigationMenu({ aree }) {
  const { categoryId } = useParams();
  const area = categoryId;
  const bgClass = `bg-${area}`;
  const { t } = useTranslation();

  return (
    <section id="s2s-mainMenu" className={bgClass}>
      <div className="container-xxl">
        <div className="row">
          <div className="col-menu offset-lg-1 col-lg-10 offset-xl-2 col-xl-8">
            <div className="mainMenuListContainer">
              <ul className="mainMenuList">
                {_map(
                  aree,
                  ({
                    id, name, news,
                  }) => (
                    <li
                      id={`mn-${id}`}
                      style={{
                        backgroundImage: `url('/img/category-${id}.jpg')`,
                        minWidth: 100,
                      }}
                      key={id}
                    >
                      <Link href={`category/${id}`}>
                        {news ? (
                          <span className="badge bg-primary">
                            {`${news} ${t('new')}`}
                          </span>
                        ) : null}
                        {name}
                      </Link>
                    </li>
                  ),
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NavigationMenu;
