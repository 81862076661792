import Message from './Message';

function Loader(props) {
  const { loading, error, children } = props;
  if (loading) {
    return (<Message message="loading" />);
  }

  if (error) {
    return (
      <Message message="error" showBackToHome>
        <p>{error}</p>
      </Message>
    );
  }

  return (children);
}

export default Loader;
