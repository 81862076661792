import { Fragment } from 'react';
import _map from 'lodash/map';
import classnames from 'classnames';

function ProgressBar({ availableLevels, currentLevel }) {
  return (
    <div className="progress-levels">
      {_map(availableLevels, (availableLevel) => {
        const isActive = currentLevel >= availableLevel;
        return (
          <Fragment key={availableLevel}>
            <div
              className={classnames('progress-level', {
                active: isActive,
              })}
            >
              {availableLevel}
            </div>
            <div className={classnames('separator', {
              active: isActive,
            })}
            />
          </Fragment>
        );
      })}
    </div>
  );
}

export default ProgressBar;
