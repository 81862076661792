import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import Link from '../SmartLink';

function Widget(props) {
  const {
    articles,
    category,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="widget-piu-letti">
      <div className="section-title section-title-col-dx mb-3 m-auto">
        <h3
          className="fw-bold text-uppercase my-3"
        >
          {t(category)}
        </h3>
      </div>

      <div className="row mb-3 justify-content-center">
        <div className="col-12">
          <ol>
            {(_map(articles, ({ title, href }) => (
              <li key={title}>
                <h4 className="title-xs text-md">
                  <Link href={href}>
                    {title}
                  </Link>
                </h4>
              </li>
            ))
            )}
          </ol>
        </div>
      </div>
    </div>

  );
}

export default Widget;
