import _map from 'lodash/map';
import SpecialItem from './Item';

function SpecialList({ data, type }) {
  return (
    <div className="special-list">
      {_map(data, (special) => (
        <SpecialItem item={special} type={type} />
      ))}
    </div>

  );
}

export default SpecialList;
