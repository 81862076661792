import _get from 'lodash/get';
import Banner from './Banner';
import useWindowSize from '../../hooks/useWindowSize';
import useTimer from '../../hooks/useTimer';

const MOBILE_TRESHOLD = 767;

function BannerCarousel({ content, timer }) {
  const { width } = useWindowSize();
  const [seconds] = useTimer({
    active: true,
    timer,
  });

  if (!content) {
    return null;
  }
  const index = seconds % content.length;
  const curr = _get(content, index, {});
  const {
    // className, href, alt, src,
    id,
    url,
    title,
    image_desktop: imageDesktop,
    image_mobile: imageMobile,
  } = curr;

  return (
    <div className="row mb-3 banner-carousel">
      <div className="col-12" key={`${id}-${index}`}>
        <Banner
          key={id}
          href={url}
          alt={title}
          src={(width <= MOBILE_TRESHOLD && imageMobile) ? imageMobile : imageDesktop}
        />
      </div>
    </div>
  );
}

export default BannerCarousel;
