import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import it from './locale/it.json';
import en from './locale/en.json';

const resources = {
  en,
  it,
};

export default function init() {
  return i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
    // manage translation via a UI
    // https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui
      resources,
      fallbackLng: 'it',
      interpolation: {
        escapeValue: false,
      },
    });
}

export const langs = Object.keys(resources);
export const langsNames = {
  en: 'eng',
  it: 'ita',
};
