/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fetchFn from '../../utils/fetchFn';
import TransWithLinks from '../TransWithLinks';
import SectionHeader from '../SectionHeader';

const { Label, Group, Control } = Form;

function InfoForm() {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [showSent, setShowSent] = useState(false);
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = (body) => {
    fetchFn({
      url: 'api/it/contacts/infos',
      method: 'POST',
      body,
    });
    setShowSent(true);
  };

  const toggleChecked = () => {
    setIsChecked(!isChecked);
  };

  if (showSent) {
    return (
      <div className="text-center" style={{ height: '300px', width: '45%', margin: 'auto' }}>
        <h1>
          {t('thank_you')}
        </h1>
        <p>
          {t('contacted_soon')}
        </p>
        <input
          type="button"
          className="btn btn-accent s2s-btn-accent text-uppercase"
          value="Okay"
          onClick={() => setShowSent(false)}
        />
      </div>
    );
  }

  return (
    <div className="info">
      <div className="comments mb-5">

        <SectionHeader
          className="section-title-col-dx"
          title={{
            text: t('more_info'),
          }}

        />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Group
            className="mb-3 text-form"
            controlId="formBasicEmail"
          >
            <Label>
              {t('email_address')}
            </Label>
            <Control
              type="email"
              placeholder="email"
              {...register('email', {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
            />
          </Group>

          <Group className="mb-3 text-form" controlId="exampleForm.ControlTextarea1">
            <Label>
              {t('make_question')}
            </Label>
            <Control
              as="textarea"
              rows={3}
              {...register('question', {
                required: true,
              })}
            />
          </Group>

          <div className="form-check mb-3">
            <label className="form-check-label" htmlFor="flexCheckDefault">
              <TransWithLinks
                className="d-inline-block"
                i18nKey="accept_privacy_policy"
                links={['page/privacy']}
              />
              <input
                className="form-check-input"
                type="checkbox"
                onChange={toggleChecked}
                value={isChecked}
                id="flexCheckDefault"
              />
            </label>
          </div>
          <input
            type="submit"
            className="btn btn-accent s2s-btn-accent text-uppercase"
            value={t('submit')}
            disabled={!isChecked}
          />
        </Form>
      </div>
    </div>
  );
}

export default InfoForm;
