import _isFunction from 'lodash/isFunction';
import _capitalize from 'lodash/capitalize';
import { useParams } from 'react-router-dom';
import DynamicPagedContent from '../components/DynamicPagedContent';
import Configuration from '../providers/config.Provider';

function Category(props) {
  const params = useParams();
  let { categoryId } = props;
  if (categoryId === undefined) {
    categoryId = params.categoryId;
  }
  const { langId } = params;
  const { getName } = Configuration().config;
  const category = _isFunction(getName) ? getName(categoryId) : '-';

  return (
    <>
      <section className="mb-4">
        <div className="row">
          <div className="col">
            <h1 className={`text-${categoryId}`}>
              {_capitalize(category)}
            </h1>
          </div>
        </div>
      </section>
      <DynamicPagedContent
        injectUrl={`articles/area/${categoryId}`}
        langId={langId}
      />
    </>
  );
}

export default Category;
