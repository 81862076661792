/* eslint-disable react/no-danger */
import _map from 'lodash/map';
import data from '../constants/socials.json';

function Socials() {
  return (
    <ul className="s2s-icon-list">
      {_map(
        data,
        ({ newIcon, url, slug }) => (
          <li key={`social-${slug}`}>
            <a href={url} target="_blank" rel="noreferrer">
              <i className={`s2s-icon icon-${slug}`} dangerouslySetInnerHTML={{ __html: newIcon }} />
            </a>
          </li>
        ),
      )}
    </ul>
  );
}

export default Socials;
