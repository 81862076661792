import { useState } from 'react';
import fetchFn from '../utils/fetchFn';
import AuthUser from '../providers/auth.Provider';

function useAPI(args = {}) {
  const {
    url,
    method = 'POST',
    onSuccess,
    requireAuth,
  } = args;
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { isAuth } = AuthUser();

  const onSubmit = (body) => {
    if (isLoading) {
      return;
    }
    if (requireAuth && !isAuth) {
      setErrorMsg('please login first');
      return;
    }
    setLoading(true);

    fetchFn({
      url,
      method,
      body,
    })
      .then(onSuccess)
      .catch(
        (err) => {
          setErrorMsg(err.message);
        },
      ).finally(
        () => setLoading(false),
      );
  };

  return {
    onSubmit,
    isLoading,
    errorMsg,
  };
}

export default useAPI;
