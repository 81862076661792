import _map from 'lodash/map';
import _join from 'lodash/join';
import Element from './Element';

function Elements(props) {
  const { data, isSide } = props;

  return (
    <>
      {_map(
        data,
        ({
          type, content, options, classes = [],
        }, id) => (
          <div className={_join(classes, ' ')} key={id}>
            <Element
              type={type}
              content={content}
              options={options}
              isSide={isSide}
            />
          </div>
        ),
      )}
    </>
  );
}

export default Elements;
