import { useState } from 'react';
import _slice from 'lodash/slice';

function Debug(props) {
  const {
    data,
    closed,
  } = props;

  const json = JSON.stringify(data, null, 2);
  const [isOpen, setIsOpen] = useState(!closed);

  const content = isOpen
    ? json
    : _slice(json, 0, 1);

  const style = {
    cont: {
      outline: '1px solid #ddd',
      backgroundColor: '#eee',
      margin: 5,
      padding: 5,
      borderRadius: 5,
    },
    btn: {
      float: 'right',
      border: 'none',
      backgroundColor: 'white',
    },
  };

  return (
    <pre style={style.cont}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        style={style.btn}
      >
        x
      </button>
      {content}
    </pre>
  );
}

export default Debug;
