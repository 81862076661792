import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import Link from '../SmartLink';
import Image from '../Image';
import getFileSrc from '../../utils/getFileSrc';

function SpecialItem({ item, type = 'articles' }) {
  const {
    id,
    title,
    subtitle,
    image,
    attachment,
    forbidden,
  } = item || {};
  const { t } = useTranslation();

  return (
    <div className="special-item">
      <Link href={`${type}/${id}`}>
        <Image url={_get(image, 'url')} alt={_get(image, 'description')} />
      </Link>
      <div className="special-item-content">
        <div>
          <Link href={`${type}/${id}`}>
            <h3>{title}</h3>
          </Link>
          <p>{subtitle}</p>
        </div>
        {attachment
          ? (
            <Link
              href={getFileSrc(attachment)}
              noPrefix
              className="mt-3 btn btn-outline-primary"
              restricted={!!forbidden}
            >
              {t('download')}
            </Link>
          )
          : null}
      </div>
    </div>
  );
}

export default SpecialItem;
