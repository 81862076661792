import CommentList from './CommentList';
import Comment from './Comment';

function CommentElement({ comments }) {
  return (
    <div className="comments">
      <Comment />
      <CommentList comments={comments} />
    </div>
  );
}

export default CommentElement;
