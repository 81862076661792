import { useParams } from 'react-router-dom';
import { InlineShareButtons } from 'sharethis-reactjs';

function ShareLinks(props) {
  const { langId } = useParams();
  const {
    href,
    imageUrl,
    description,
    title,
  } = props;

  return (
    <div className="mb-5">
      <InlineShareButtons
        config={{
          alignment: 'left', // alignment of buttons (left, center, right)
          color: 'social', // set the color of buttons (social, white)
          enabled: true, // show/hide buttons (true, false)
          font_size: 16, // font size for the buttons
          labels: 'null', // button labels (cta, counts, null)
          language: langId, // which language to use (see LANGUAGES)
          networks: [ // which networks to include (see SHARING NETWORKS)
            'facebook',
            'linkedin',
            'twitter',
            'email',
            'print',
          ],
          padding: 12, // padding within buttons (INTEGER)
          radius: 4, // the corner radius on each button (INTEGER)
          show_total: false,
          size: 40, // the size of each button (INTEGER)

          url: href, // (defaults to current url)
          image: imageUrl,
          description, // (defaults to og:description or twitter:description)
          title,
        }}
      />
    </div>
  );
}
export default ShareLinks;
