import _map from 'lodash/map';
import { Link } from 'react-router-dom';
import { langs, langsNames } from '../i18n/i18n';
import Configuration from '../providers/config.Provider';

function LanguageSwitcher() {
  const {
    config,
    translatedUrl,
  } = Configuration();
  const { langId, setLangId } = config;

  return (
    <ul className="s2s-lang-list">
      {_map(
        langs,
        (lang, index) => (
          <li key={`/${lang}_${index}`}>
            <Link
              to={langId === lang || !translatedUrl ? `/${lang}` : translatedUrl}
              className={`${langId === lang ? 's2s-lang-active' : 's2s-lang-deactive'} text-uppercase`}
              onClick={() => setLangId(lang)}
            >
              {langsNames[lang]}
            </Link>
          </li>
        ),
      )}
    </ul>
  );
}

export default LanguageSwitcher;
