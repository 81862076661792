import { Trans } from 'react-i18next';
import _map from 'lodash/map';
import Link from './SmartLink';

function TransWithLinks({
  className,
  i18nKey,
  links,
}) {
  return (
    <div className={className}>
      <Trans
        i18nKey={i18nKey}
      >
        {_map(links, (href) => (
          <Link
            key={href}
            href={href}
            targetBlank
          >
            .
          </Link>
        ))}
      </Trans>
    </div>
  );
}

export default TransWithLinks;
