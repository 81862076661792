import Link from '../SmartLink';
import Image from '../Image';
import PrettyDate from '../PrettyDate';

function Wrapper({ href, children }) {
  if (href) {
    return (
      <Link href={href} className="link-img-container">
        {children}
      </Link>
    );
  }
  return (
    <div className="link-img-container" style={{ cursor: 'pointer' }}>
      {children}
    </div>
  );
}

function VideoCard(props) {
  const {
    size, image, title, href, desc, date,
  } = props;

  return (
    <div className={`card card-video-${size}`}>
      <article>

        <Wrapper href={href}>
          <div className="img-container">
            <Image url={image} alt={desc} className="card-img-top" />
          </div>
          {size === 'sm'
            ? (
              <span className="material-icons-outlined">ondemand_video</span>
            )
            : (
              <span className="material-icons-outlined">play_circle_filled</span>
            )}
        </Wrapper>

        <div className="card-body">
          {href
            ? (
              <h3 className={`title-${size} card-title`}>
                <Link href={href}>
                  {title}
                </Link>
              </h3>
            )
            : ''}

          {date
            ? (
              <div className="text-xs text-uppercase text-primary">
                <PrettyDate date={date} />
              </div>
            )
            : null}
        </div>

      </article>
    </div>

  );
}

export default VideoCard;
