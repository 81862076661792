import { useEffect, useState } from 'react';
import fetchFn from '../utils/fetchFn';

function useAuth() {
  const [isAuth, setAuth] = useState(false);
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  function login(body, onSuccess) {
    setLoading(true);

    return fetchFn({
      url: 'api/it/login',
      method: 'POST',
      body,
    }).then(
      ({ user: u, status }) => {
        setAuth(status);
        setUser(u);
        setError();
        if (onSuccess) {
          onSuccess();
        }
      },
    ).catch(
      (err) => {
        setError(err.message);
      },
    ).finally(
      () => {
        setLoading(false);
      },
    );
  }

  function logout() {
    return fetchFn({
      url: 'api/it/logout',
      method: 'POST',
    }).then(
      () => {
        setAuth(false);
        setUser({});
      },
    );
  }

  function register(body) {
    setLoading(true);

    return fetchFn({
      url: 'api/it/register',
      method: 'POST',
      body,
    }).then(
      ({ user: u, status }) => {
        setAuth(status);
        setUser(u);
        setError();
      },
    ).catch(
      (err) => {
        setError(err.message);
      },
    ).finally(
      () => {
        setLoading(false);
      },
    );
  }

  function check() {
    fetchFn({
      url: 'api/it/user',
      method: 'GET',
    }).then(
      (res) => {
        setAuth(true);
        setUser({ ...res });
      },
    ).catch(
      () => {
        setAuth(false);
        setUser({});
      },
    );
  }

  // get initial values
  useEffect(check, []);

  return {
    isAuth,
    isAuthModalOpen,
    setAuthModalOpen,
    login,
    logout,
    register,
    check,
    user,
    error,
    loading,
  };
}

export default useAuth;
