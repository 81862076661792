/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import fetchFn from '../utils/fetchFn';
import Link from '../components/SmartLink';

function Logout() {
  const { t } = useTranslation();
  const [, , removeCookie] = useCookies();
  useEffect(
    () => {
      removeCookie('email');
      fetchFn({
        url: 'account/logout',
      });
    },
    [],
  );

  return (
    <div className="columns is-centered text-center">
      <div className="is-half center">
        <h3>
          { t('logout_success')}
        </h3>
        <Link
          className="btn btn-primary"
          href="login"
        >
          { t('login')}
        </Link>
      </div>
    </div>
  );
}

export default Logout;
