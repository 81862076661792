/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import VideoCard from './Card';
import BannerList from '../Banner';
import VideoElement from './Element';
import SectionHeader from '../SectionHeader/Header';
import createSlug from '../../utils/createSlug';

function Video(props) {
  const {
    mainVideo,
    main,
    sideVideos = [],
    banners = [],
    videoImage = {},
  } = props;
  const { t } = useTranslation();

  return (
    <div className=" bg-primary-dark s2s-section-bg mb-3 video container-xxl">

      <SectionHeader
        className="section-title section-title-no-line mb-4 mt-3"
        title={{
          text: t('video'),
          className: 'text-uppercase',
          href: 'videos',
        }}
        iconHref="videos"
      />

      <div className="row mb-3">
        <div className="col-12">
          <VideoElement
            id={mainVideo}
            image={videoImage}
            title={main.title}
            date={main.published_at}
            href={`video/${createSlug(main.title)}/${main.id}`}
          />
        </div>
      </div>

      <div className="row mb-3">
        {_map(sideVideos, ({
          image = {}, title, id, published_at: publishedAt,
        }, idx) => (
          <div className="col-md-4" key={idx}>
            <VideoCard
              size="sm"
              image={image?.url}
              title={title}
              date={publishedAt}
              href={`video/${createSlug(title)}/${id}`}
            />
          </div>
        ))}
      </div>

      <div className="pb-3">
        <BannerList
          isHorizontal
          content={banners}
        />
      </div>

    </div>
  );
}

export default Video;
