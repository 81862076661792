import { useTranslation } from 'react-i18next';
import SmartLink from '../SmartLink';

function SubscribeButton(props) {
  const { t } = useTranslation();
  const {
    id,
    label = t('activate_now'),
  } = props;

  return (
    <div className="box-footer">
      <SmartLink
        href={`subscribe/1?plan=${id}`}
        className="btn btn-accent s2s-btn-accent"
      >
        {label}
      </SmartLink>
    </div>

  );
}

export default SubscribeButton;
