import { useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import fetchFn from '../utils/fetchFn';
// import useAuth from './useAuth';
import AuthUser from '../providers/auth.Provider';

function useFetch(args) {
  const {
    url,
    method = 'GET',
    body,
    type = 'application/json',
    then = null,
    // isPrivate = false,
  } = args;

  const isAuth = AuthUser();

  // skip auth api calls if not authenticated
  // TODO testare se funziona usando un provider invece che l'hook
  // const { isAuth } = AuthUser();
  // if (isPrivate && !isAuth) {
  //   return {
  //     data: {},
  //     error: 'please login first',
  //     loading: false,
  //   };
  // }

  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [prevBody, setPrevBody] = useState(body);
  const [lastUpdate, setLastUpdate] = useState('');

  const sameBody = _isEqual(body, prevBody);
  if (!sameBody) {
    setPrevBody(body);
    setLastUpdate(Date.now());
  }

  useEffect(() => {
    fetchFn({
      url, method, body, type,
    })
      .then((actualData) => {
        setData(actualData);
        if (then) {
          then(actualData);
        }
        setError(null);
      })
      .catch((err) => {
        setError(err.message);
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url, method, lastUpdate, type, isAuth]);

  return {
    data,
    error,
    loading,
  };
}

export default useFetch;
