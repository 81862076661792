const getSizeWidth = (size, isHorizontal) => {
  if (isHorizontal) {
    return 'auto';
  }
  switch (size) {
    case 's':
    case 'sm':
      return '32%';
    case 'm':
    case 'md':
      return '46%';
    case 'l':
    case 'lg':
      return '65%';
    case 'full':
    case 'xl':
      return '100%';
    default:
      return 'auto';
  }
};

export default getSizeWidth;
