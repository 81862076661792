import Image from '../Image';
import Link from '../SmartLink';

function Banner(props) {
  const {
    href, alt, src, className = '',
  } = props;
  if (!src) {
    return null;
  }
  return (
    <div className={`s2s-banner mb-3 ${className}`}>
      <Link href={href} className="s2s-adv">
        <Image url={src} alt={alt} />
      </Link>
    </div>
  );
}

export default Banner;
