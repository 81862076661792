import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import {
  Navigate, useParams, useSearchParams,
} from 'react-router-dom';
import Authenticate from '../components/Authenticate';
import ChoosenPlanCard from '../components/ChoosenPlanCard/ChoosenPlanCard';
import ProgressBar from '../components/ProgressBar';
import SubscriptionHeader from '../components/Subscriptions/Header';
import Configuration from '../providers/config.Provider';

function Subscribe1() {
  const { langId } = useParams();
  const [searchParams] = useSearchParams();
  const {
    subscriptions,
  } = Configuration().config;

  const id = parseInt(searchParams.get('plan'), 10);
  const data = _find(subscriptions, { id });

  if (_isEmpty(data)) {
    console.error('subscription not found');
    return (<Navigate to="../subscription" />);
  }
  const {
    duration,
    price,
    features,
  } = data;

  return (
    <div
      className="subscription-page"
      style={{
        width: '50%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SubscriptionHeader />

      <ProgressBar availableLevels={[1, 2, 3, 4]} currentLevel={1} />

      <ChoosenPlanCard
        duration={duration}
        price={price}
        features={features}
      />

      <div className="d-flex info justify-content-center">
        <Authenticate toggle>
          <Navigate to={`/${langId}/subscribe/2?plan=${id}`} />
        </Authenticate>
      </div>
    </div>
  );
}

export default Subscribe1;
