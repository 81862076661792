/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import VideoCard from './Card';
import YoutubeEmbed from '../YoutubeEmbed';

function VideoElement({
  id,
  image,
  title,
  href,
  date,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    isOpen || !image
      ? <YoutubeEmbed id={id} />
      : (
        <div onClick={() => setIsOpen(true)} className="img-container">
          <VideoCard
            size="xl"
            image={image?.url}
            desc={image?.description}
            title={title}
            href={href}
            date={date}
            icon="play_circle_filled"
          />
        </div>
      )
  );
}

export default VideoElement;
