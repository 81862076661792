import _isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import getFileSrc from '../utils/getFileSrc';

function DownloadButton({ url }) {
  const { t } = useTranslation();

  if (_isEmpty(url)) {
    return null;
  }
  return (
    <a
      className="mb-5 btn btn-outline-primary"
      style={{ textTransform: 'uppercase' }}
      href={getFileSrc(url)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('download')}
    </a>
  );
}

export default DownloadButton;
