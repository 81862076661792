import { useEffect, useState } from 'react';

const useTimer = (opts = {}) => {
  const {
    active = false,
    timer = 3000,
  } = opts;
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(active);
  const toggle = () => setIsActive(!isActive);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((sec) => sec + 1);
      }, timer);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return [seconds, isActive, toggle];
};

export default useTimer;
