export const CONTENTS = [
  'magazines',
  'specials',
  'events',
  'podcasts',
  'videos',
  'photogalleries',
];

export const S2S = [
  'page/about',
  'page/ads',
  'page/contacts',
];

export const ACTIONS = [
  'subscribe',
  'newsletter',
];
